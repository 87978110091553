@use "../../../styles/settings/_Color.scss" as color;

body {
	background-color: color.$Neutral95;
}

.p31_container {
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	>.adjust {
		max-width: 1200px;
		margin: 0 auto;
		padding-top: 45px;
		width: 100%;
	}

	>.main {
		min-height: 100%;
		width: 100%;
		padding-top: 45px;
		flex: 1;
		display: flex;
		flex-direction: column;
		width: 100%;
	}
}
