@use "../../../styles/settings/_Color.scss" as color;
@use "../../../styles/settings/FontFamily" as font_family;

.o25_modal_body {
    padding-top: 0 !important;
    font-size: 20px;
    text-align: center;
    font-family: 'BIZUDGothic-Bold';

    .o25_link,
    .o25_line {
        max-width: 25%;
        align-items: center;
    }

    .o25_button {
        min-width: 72px !important;
        height: 72px !important;
        padding: 0;
        margin: 0 !important;
        margin-bottom: 10px !important;
        background-color: color.$Neutral_Variant95;
        border: none;
        border-radius: 50% !important;
        filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.15)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3));

        &:hover {
            opacity: .6;
        }

        &:focus {
            border: none !important;
            box-shadow: none !important;
        }

        .o25_link_img {
            min-width: 48px !important;
            height: 48px !important;
        }
    }
}
