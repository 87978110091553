@use "../../../styles/settings/FontFamily" as font_family;

.o39_modal_body {
    padding-top: 0;
    padding-bottom: 36px;
    align-items: center;
    font-family: 'BIZUDGothic-Bold';

    >p {
        font-size: 16px !important;
    }

    >.o39_stack {
        justify-content: center;

        >.btn {
            min-width: 110px !important;
            font-size: 14px !important;
        }
    }

    >.o39_title {
        padding-bottom: 20px;
        text-align: center;
        font-size: 14px;
    }
}
