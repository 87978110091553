@use "../../styles/settings/_FontFamily.scss" as font_family;
@use "../../styles/settings/_Color.scss" as color;

.p1_main {
    display: flex;
    flex-direction: column;
    align-items: center;

    >h1 {
        margin-top: 65px;
        font-family: "BIZUDGothic-Bold";
    }

    >.o2_tabs {
        margin-top: 30px;
    }

    >.content {
        width: 100%;
        margin-top: 94px;
    }

    .error {
        border-color: red !important;

        * {
            border-color: red !important;
        }
    }
}