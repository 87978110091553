@use "../../styles/settings/_Color.scss" as color;
.m13_listGroup {
    >.list-group-item {
        border: none;
        >.m13_responsibility {
            font-size: 14px;
            max-height: 150px;
            overflow-y: auto;
            text-overflow: ellipsis;

            &::-webkit-scrollbar {
                /* スクロールバーの幅 */
                width: 5px;
            }

            &::-webkit-scrollbar-thumb {
                /* ツマミの色 */
                background: color.$Neutral50;
                /* ツマミ両端の丸み */
                border-radius: 10px;
            }

            &::-webkit-scrollbar-track {
                /* トラックの色 */
                background: color.$Neutral80;
                /* トラック両端の丸み */
                border-radius: 10px;
            }
        }
    }
}
