@use "../../../styles/settings/_Color.scss" as color;

.m34_profile_wrapper {
    margin-bottom: 40px;
    padding: 40px 0;
    background-color: #ebebeb;

    .m34_swiper {
        height: 156px;
        max-width: 2250px;
        margin: 22px auto 14px;
        padding: 10px 0;

        .swiper-wrapper {
            transition-timing-function: linear;
        }

        .swiper-slide {
            width: 328px !important;
            .m34_profile {
                padding: 0.5em 1em;
                background: color.$Neutral100;
                border-radius: 18px;
                cursor: pointer;
                box-sizing: border-box;
                filter: drop-shadow(0 3px 3px rgba(color.$Neutral0, 0.25));
                transition: 0.2s;
                justify-content: flex-start;

                &:hover {
                    background: color.$Neutral90;
                    filter: none;
                }

                .m34_icon {
                    background-color: color.$Neutral95;
                    width: 48px;
                    aspect-ratio: 1/1;
                }

                .m34_profile_info {
                    align-items: flex-start;
                    .m34_tag_name {
                        font-size: 14px;
                        color: color.$Neutral50;
                    }
                }
            }
        }
    }

    .m34_more_btn_wrapper {
        max-width: 1200px;

        .m34_more_btn {
            width: 12em;
            height: 32px;
            font-size: 12px;
            margin: 1.5em 1em 0 auto;
            background-color: color.$Primary40;
            border-color: color.$Primary40;
            color: color.$Neutral100;
            transition: all 0.15s ease;

            &:hover {
                background-color: color.$Neutral100;
                color: color.$Primary40;
                border: 2px solid color.$Primary40;

                path {
                    fill: color.$Primary40;
                }
            }

            &:active {
                background-color: color.$Neutral100;
                color: color.$Primary40;
                border: 1px solid color.$Primary40;

                path {
                    fill: color.$Primary40;
                }
            }

            path {
                fill: color.$Neutral100;
                transition: all 0.15s ease;
            }
        }
    }
}
