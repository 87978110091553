@use "../../styles/settings/_Color.scss" as color;

.a1_usermenu {
    >.a1_usericon {
        padding: 0;
        border: none;
        background: transparent;
        object-fit: cover;

        >.a1_img {
            width: 28px;
            height: 28px;
            border-radius: 50%;
        }
    }
}

.popover {
    width: 250px;
    border: none;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
    z-index: 1;

    >.popover-arrow {
        display: none;
    }

    >.a1_lists {
        background-color: #F4F3F7; //色追加必要
        align-items: center;

        >.a1_profile_wrapper {
            width: 100%;
            margin-bottom: 25px;
            position: relative;

            >figure {
                width: 100%;
                overflow: hidden;
                border-radius: .375rem .375rem 0 0;
                margin-bottom: 28px;

                >.a1_list_bg_usericon {
                    width: 100%;
                    height: 64px;
                    object-fit: cover;
                    border-radius: .375rem .375rem 0 0;
                    position: relative;
                    filter: blur(3px);
                }
            }

            >p {
                text-align: center;
                margin: 0;
            }

            >p:nth-of-type(1) {
                font-size: 16px;
                font-weight: bold;
            }

            >p:nth-of-type(2) {
                font-size: 10px;
            }

            >.a1_list_usericon {
                width: 70px;
                height: 70px;
                border-radius: 100%;
                box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
                position: absolute;
                transform: translate(-50%, -50%);
                top: 55px;
                left: 50%;
                object-fit: cover;
            }
        }

        >.list-group-item {

            >a,
            >button {
                display: flex !important;
                align-items: center;
                width: 218px;
                height: 28px;
                line-height: 12px;
                font-size: 12px;

                >svg {
                    width: 14px;
                    margin-right: 3px;
                }
            }

            >button:hover {
                background-color: color.$Error40;
                color: #FFF;

                path {
                    fill: #FFF;
                }
            }

            padding: 0;
            border: none;
            border-radius: 7px;

            >a {
                padding: 8px 12px 6px 12px;
                border-radius: 7px;
                display: inline-block;
                text-decoration: none;
                color: color.$Neutral10;
                background-color: color.$Neutral100;
            }

            >button {
                background: none;
                border: none;
                color: color.$Neutral10;
                text-align: left;
            }
        }

        >.list-group-item:not(:nth-last-child(1)) {
            margin-bottom: 10px;
        }

        >.list-group-item:nth-last-child(1) {
            margin-bottom: 15px;
        }

        >.list-group-item:hover,
        >.list-group-item>a:hover {
            background-color: color.$Secondary90;
            opacity: 1 !important;
        }
    }
}


@media (max-width: 767px) {

    .a1_usermenu {
        padding-top: 0;

        >.a1_lists {
            display: flex;

            .a1_profile_wrapper {
                display: none;
            }

            .list-group-item {
                background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
                border: none;
                padding-right: 0;
                padding-left: 0;
                border-top: 1px solid color.$Neutral70;

                >a,
                >button {
                    line-height: auto;
                }

                >a {
                    >svg {
                        margin-right: 8px;
                    }
                }

                >button {
                    background: none;
                    border: none;
                    color: color.$Neutral10;
                    text-align: left;
                    padding: 0 0 0 2px;
                    font-weight: bold;

                    >svg {
                        margin-right: 8px;
                    }
                }
            }

            .list-group-item:nth-child(3) {
                display: none;
            }
        }
    }
}
