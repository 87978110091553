@use "../../../styles/settings/_Color.scss" as color;
@use "../../../styles/settings/_FontFamily.scss" as font-family;

.p20_title {
  font-size: 24px;
  margin-top: 65px;
  font-family: AtkinsonHyperlegible-Regular;
}

.p20_accordion_wrapper {
  --bs-border-color: none;
  --bs-accordion-border-radius: unset;
  --bs-accordion-inner-border-radius: unset;
  --bs-accordion-btn-focus-box-shadow: unset;
  > .accordion-item {
    border-bottom: 1px solid rgba(color.$Neutral_Variant50, 30%);
    > .accordion-header {
      > .accordion-button {
        display: block;
        position: relative;
        background: none;
        color: color.$Neutral10;
        padding: 2rem 0 1rem 3rem;
        font-weight: bold;
        &::before {
          content: "";
          width: 1rem;
          height: 1rem;
          margin: 0 0 0 1rem;
          position: absolute;
          top: 50%;
          left: 0;
          transition: background 0.3s ease, transform 0.3s ease;
          background: url(../../../assets/add.svg) no-repeat center center;
        }
        &:not(.collapsed)::before {
          background: url(../../../assets/minus.svg) no-repeat center center;
          transform: rotate(180deg);
        }
      }
    }
    > .accordion-collapse {
      > .accordion-body {
        font-size: 14px;
        color: color.$Secondary50;
      }
    }
  }
}
