@use "../../styles/settings/_FontFamily.scss" as font_family;
@use "../../styles/settings/_Color.scss" as color;

.m25_container {
    padding: 0 4%;

    >.user {
        width: 100%;

        >.item {
            display: flex;
            align-items: center;
            padding: 0 5%;
            min-height: 42px;

            >.a7_box {
                width: 37%;
                text-wrap: nowrap;
                align-items: center;
                max-width: 200px;

                >img {
                    margin-bottom: 0;
                }
            }

            >p {
                margin-right: 1.5%;
            }

            >.input_text,
            >.m25_text {
                width: 50%;
            }

            >.m25_col_butoon {
                padding: 5px;
                min-width: 110px;
                height: 40px;
                background-color: color.$Neutral_Variant95 !important;
                font-family: 'BIZUDGothic-Bold' !important;
                --bs-btn-border-color: none;
                filter: drop-shadow(1px 1.5px 1px color.$Neutral20);
                color: color.$Neutral_Variant30 !important;

                &:focus {
                    border: none !important;
                    box-shadow: none !important;
                }

                &:hover {
                    background-color: color.$Neutral_Variant80 !important;
                }

                &:active {
                    background-color: color.$Neutral_Variant70;
                }

                svg {
                    vertical-align: -7px;
                    margin-right: 6px;
                    filter: contrast(0.5);
                }
            }

            >.icon_delete {
                background-color: color.$Error50 !important;

                svg {
                    filter: contrast(1);

                    path {
                        fill: color.$Primary99 !important;
                    }
                }

                &:hover {
                    background-color: color.$Error40 !important;
                }

                &:active {
                    background-color: color.$Error40;
                }

                span {
                    color: color.$Primary99 !important;
                }
            }

            .change_icon {
                width: calc(100% / 3);
                margin-right: 1.5%;
            }
        }
    }

    >.m25_title {
        font-size: 20px;
        font-family: 'BIZUDGothic-Bold';
    }

    >.m25_sns_container {
        padding: 0 2.5%;

        >.m25_sns_group {
            display: block;
            justify-content: center;
        }

        >.m25_buttons {

            .m25_url_button,
            .m25_save_button {
                padding: auto;
                filter: drop-shadow(1px 1px 2px color.$Neutral20);
                border: none !important;

                &:focus {
                    box-shadow: none !important;
                }

            }

            >.m25_url_button {
                font-size: 14px !important;
                width: 216px;
                height: 40px;
                background: color.$Neutral_Variant92;

                >img {
                    vertical-align: -7px;
                    margin-right: 5px;
                }

                &:hover {
                    background: color.$Neutral_Variant80 !important;
                }

                &:active {
                    background: color.$Neutral_Variant70 !important;
                }
            }

            >.m25_save_button {
                width: 260px;
                height: 60px;
                background-color: color.$Primary40;
                font-size: 16px !important;

                &:hover {
                    background: color.$Primary30;
                }

                &:active {
                    background: color.$Primary20 !important;
                }
            }
        }
    }
}