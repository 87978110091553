body {
    font-family: BIZUDGothic-Regular;
}

//英語用
@font-face {
    font-family: 'AtkinsonHyperlegible-Regular';
    src: url('../../../public/Fontfamily/AtkinsonHyperlegible-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

//英語用
@font-face {
    font-family: 'AtkinsonHyperlegible-Bold';
    src: url('../../../public/Fontfamily/AtkinsonHyperlegible-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

//日本語用
@font-face {
    font-family: 'BIZUDGothic-Regular';
    src: url('../../../public/Fontfamily/BIZUDGothic-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

//日本語用
@font-face {
    font-family: 'BIZUDGothic-Bold';
    src: url('../../../public/Fontfamily/BIZUDGothic-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
