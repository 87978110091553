@use "../../styles/settings/_Color.scss" as color;
@use "../../styles/settings/_FontFamily.scss" as font_family;


.m2_error , .m2_attention{
    border-radius: 5px;
    height: 110px;
    width: 70%;
    align-items: center;
    display: flex;
    margin: 10px 0;

    > svg{
        width: 80px;
        height: 80px;
        margin: 0 15px;
    }

    > .m2_p_group {
        display: flex;
        flex-direction: column;
        margin-right: 10px;

        > p {
            @media screen and (min-width: 769px) {
                font-size: 20px;
            }
            font-size: 14px;
            margin: 0 !important;
            padding-left: 30px;
            font-family: "BIZUDGothic-Bold";
        }

    }

}
.m2_error {
    background-color: color.$Error90;
    border: 1px solid color.$Error40;

    > svg {
        filter: brightness(0) saturate(100%) invert(14%) sepia(79%) saturate(4460%) hue-rotate(355deg) brightness(91%) contrast(87%);
    }

    > .m2_p_group {

        > p {
            color: color.$Error40;
        }
    }
}

.m2_attention {
    background-color: #fffee5;
    border: 1px solid #cead00;

    > svg {
        filter: brightness(0) saturate(100%) invert(54%) sepia(75%) saturate(1312%) hue-rotate(20deg) brightness(105%) contrast(104%);
    }

    > .m2_p_group {

        > p {
            color: #cead00;
        }
    }
}
