@use "../../styles/settings/_Color.scss" as color;


.m5_change_icon {
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    width: 100px;

    &::after {
        content: "編集";
        right: 0;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 35%;
        color: color.$Primary100;
        text-align: center;
        background-color: rgba(45, 48, 57, 0.65);
    }

    >.icon_field {
        width: 100px;
        height: 100px;
        position: absolute;
        opacity: 0;
        z-index: 10;
        cursor: pointer;
    }

    >.icon {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: #eee;
        position: relative;
        overflow: hidden;
        object-fit: cover;
    }

    &:hover {
        opacity: .7;
        transition: opacity 0.3s;
    }
}

.m5_drop_wrapper {
    width: 100%;
    min-width: 260px;
    height: 216px;
    border: 3px dashed color.$Neutral20;
    border-radius: 5px;
    padding: 25px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    justify-content: space-around;

    >.m5_drop_field {
        cursor: pointer;
        top: 0;
    }

    >.m5_svg_wrapper {
        width: 187px;
        height: 150px;
        background-color: color.$Neutral_Variant80;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;

        >.m5_svg {
            width: 48px;
            height: 48px;

            path {
                fill: color.$Neutral_Variant30;
            }
        }
    }

    >.m5_drop_text {
        margin: 0;
        text-align: center;
        vertical-align: middle;
    }

    >.m5_dropped_img {
        width: 187px;
        height: 150px;
        border-radius: 5px;
    }
}

.image_error {
    border-color: color.$Error40 !important;
    color: color.$Error40 !important;
}
