@use "../../../styles/settings/_Color.scss" as color;
@use "../../../styles/settings/FontFamily" as font_family;

.o19_modal_body {
    padding-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    padding-bottom: 48px;
    height: auto;
    font-size: 20px;

    >p {
        font-family: 'BIZUDGothic-Bold';
    }

    >.o19_stack {
        justify-content: center;
    }

    .btn {
        min-width: 180px;
        font-size: 20px;
        margin: 0 10px;
    }
}
