.o30_container {
	>p {
		margin-top: 15px;
		font-size: 16px !important;
	}
	>.radio_buttons>div {
		margin-top: 13px;
		padding: 0;
		margin-right: 26px;
	}
}