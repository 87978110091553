@use "../../../styles/settings/Color" as color;
@use "../../../styles/settings/FontFamily" as font-family;

.p17_container {
    margin: auto;

    >.p17_error_box {
        display: flex;
        justify-content: center;
        align-items: center;
        color: color.$Error60;

        >.p17_error_logo {
            width: 80px;
            height: 80px;
            margin-right: 22px;

            path {
                fill: color.$Error60;
            }
        }

        >.p17_text_box {
            >h3 {
                font-family: "AtkinsonHyperlegible-Regular";
                font-size: 32px;
                margin: 0;
            }

            >p {
                font-family: "BIZUDGothic-Bold";
                font-size: 16px;
                margin: 0;
            }
        }
    }
}