@use "../../../styles/settings/_Color.scss" as color;
@use "../../../styles/settings/_FontFamily.scss" as font_family;

//モーダルウィンドウ
.o21_modal_body {
    padding: 0 80px;
    padding-bottom: 30px;
    align-items: center;

    .o21_pdf-btn {
        width: 400px;
        height: 71px;
        margin-bottom: 32px;
        border: 2px solid color.$Neutral60;
        background: color.$Neutral95;
        color: color.$Neutral40;
        font-size: 16px;
        font-weight: 900;

        svg {
            margin-left: 5px;
        }

        &:hover {
            background: color.$Neutral90;
            color: color.$Neutral10;
            border: 2px solid color.$Neutral60;
        }

        &:active {
            background: color.$Neutral80 !important;
        }
    }

    .o21_copy {
        width: 400px;
        height: 42px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 5px;
        outline: 2px solid color.$Neutral60;
        background: color.$Secondary100;
        margin-top: 35px;

        .o21_span {
            font-size: 12px;
            overflow-x: hidden;
            text-overflow: ellipsis;
            padding: 5px 10px;
            font-family: "AtkinsonHyperlegible-Bold";

            &:hover {
                overflow-x: scroll;
                text-overflow: clip;

                &::-webkit-scrollbar {
                    height: 3px;
                    /* スクロールバーの高さ */
                }

                &::-webkit-scrollbar-thumb {
                    background: color.$Neutral30;
                    /* ツマミの色 */
                    border-radius: 7px;
                    /* ツマミ両端の丸み */
                    margin-left: 20px;
                }

                &::-webkit-scrollbar-track {
                    background: color.$Neutral80;
                    /* トラックの色 */
                    border-radius: 7px;
                    /* トラック両端の丸み */
                    margin-left: 20px;
                }
            }
        }

        .o21_copy_button {
            font-size: 16px;
            font-weight: bold;
            color: color.$Neutral40;
            background: color.$Neutral95;
            border-radius: 0 5px 5px 0;
            height: 42px;
            min-width: 100px !important;
            font-size: 16px !important;
            margin: 0 !important;
            border: none;
            border-left: solid 2px color.$Neutral60 !important;

            &:hover {
                background: color.$Neutral90;
                color: color.$Neutral10;
            }

            &:active {
                background: color.$Neutral80 !important;
            }
        }
        .success_copy {
            font-size: small;
            position: absolute;
            bottom: 5px;
            right: 10px;
            background: color.$Primary40;
            color: color.$Primary100;
            padding: 10px;
            border-radius: 5px;
        }
    }
}
