@use "../../styles/settings/_FontFamily.scss" as font_family;
@use "../../styles/settings/_Color.scss" as color;
.m24_listGroup {
    min-width: 220px;
    font-family: "AtkinsonHyperlegible-Regular", "BIZUDGothic-Regular";
    > .list-group-item {
        font-size: 16px;
        background-color: transparent;
        .a7_label {
            margin-left: 0;
        }
        border: none;
        > .m24_status_box {
            margin-top: 5px;
            margin-left: 5px;
            input {
                font-family: "AtkinsonHyperlegible-Bold", "BIZUDGothic-Bold";
                font-size: 12px;
                &:focus{
                    box-shadow: none;
                }
                &::-webkit-search-cancel-button {
                    -webkit-appearance: none; /* デフォルトの外観を削除 */
                    height: 10px; /* 高さ */
                    width: 10px; /* 幅 */
                    background: url("../../assets/close.svg") no-repeat center center; /* カスタムアイコン */
                    cursor: pointer;
                }
            }
            button {
                background-color: color.$Error40;
                border: none !important;
                padding: 0;
                width: 32px;
                svg {
                    width: 20px;
                    height: 20px;
                    path {
                        fill: color.$Error100;
                    }
                }
            }
            .m24_new_button {
                background-color: color.$Primary70;
                border: dotted 1px color.$Error0 !important;
                color: color.$Error0;
                size: 18px;
            }
        }
    }
}
