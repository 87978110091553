.o37_info_container {
    width: 100%;
    .a7_box {
        margin-bottom: 10px;
    }
    > .o37_top_content {
        width: 100%;
        margin-bottom: 10px;
        display: flex;
        @media screen and (max-width: 1000px) {
            display: block;
        }
        > .m24_listGroup {
            @media screen and (max-width: 1000px) {
                min-width: 100%;
                margin-left: 0;
            }
        }
        > .o37_public_relations {
            min-width: 80%;
            padding-left: 10px;
            padding-right: 8%;
            padding-top: 8px;
            @media screen and (max-width: 1000px) {
                width: 100%;
                padding-right: 10px;
            }
            > .a7_box {
                > .a7_label {
                    margin-left: 0;
                }
            }
            > .o37_PR_text {
                padding-left: 10px;
                min-height: 180px;
                overflow-wrap: break-word;
                white-space: pre-wrap;
            }
        }
    }
    .o37_item_lists {
        margin-bottom: 50px;
        .a7_box {
            margin-bottom: 30px;
        }
    }
}
