@use "../../styles/settings/_Color.scss" as color;

.o1_wapper {
  width: 116px;
  height: 116px;
  position: relative;
  display: flex;
  > .box {
    overflow: hidden;
    &::after {
      content: "";
      border-radius: 50%;
      background-color: color.$Primary40;
      width: 116px;
      height: 116px;
      display: block;
    }
    position: absolute;
    width: 116px;
    height: 116px;
    mask-image: linear-gradient(
      -45deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 1) 50%
    );
    -webkit-mask-image: linear-gradient(
      -45deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 1) 50%
    );
    mask-size: 200% 200%;
    -webkit-mask-size: 200% 200%;
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    animation: o1_fadein 0.5s forwards ease-in-out;
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 62px;
    left: 38px;
    transform: scaleX(-1) rotate(135deg);
    -webkit-transform: scaleX(-1) rotate(135deg);
    -moz-transform-origin: left top;
    -ms-transform-origin: left top;
    -webkit-transform-origin: left top;
    transform-origin: left top;
    animation: o1_check 0.3s 0.3s forwards ease-in;
  }
}

@keyframes o1_fadein {
  0% {
    mask-position: 130% 130%;
    -webkit-mask-position: 130% 130%;
  }
  100% {
    mask-position: 0% 0%;
    -webkit-mask-position: 0% 0%;
  }
}

@keyframes o1_check {
  0% {
    border-right: 0px solid color.$Primary100;
    border-top: 0px solid color.$Primary100;
    height: 0px;
    width: 0px;
  }
  10% {
    border-right: 0px solid color.$Primary100;
    border-top: 6px solid color.$Primary100;
  }
  50% {
    border-right: 6px solid color.$Primary100;
    border-top: 6px solid color.$Primary100;
    height: 0px;
    width: 18px;
  }
  100% {
    border-right: 6px solid color.$Primary100;
    border-top: 6px solid color.$Primary100;
    height: 46px;
    width: 18px;
  }
}
