@use "../../../styles/settings/_Color.scss" as color;
@use "../../../styles/settings/_FontFamily.scss";

.o31_container {
	margin-top: 17px;
	>tr {
		display: flex;
		justify-content: center;
		margin-top: 25px;
		>th,>td {
			flex: 1;
		}
		>td {
			text-align: left;
		}
	}
	>.page_buttons {
		margin-top: 69px !important;
	}
}