.m1_ListGroup {
    width: 390px;
    margin: auto 0;
        justify-content: flex-end;
    @media screen and (max-width: 800px) {
        margin: 10px auto;
    }
    .list-group-item {
        padding: 0;
        margin-left: 20px;
        border: none;
        background: transparent;
        @media screen and (max-width: 800px) {
                margin: 0 auto;
            }
        img {
            height: 40px;
            @media screen and (max-width: 800px) {
                height: 30px;
            }
        }
    }
}
