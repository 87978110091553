@use "../../styles/settings/_FontFamily.scss" as font_family;
@use "../../styles/settings/_Color.scss" as color;

.o7_navbar {
    padding: 0;
    position: fixed;
    width: 100%;
    z-index: 1000;
    font-family: "BIZUDGothic-Bold";
    font-size: 16px;

    > .container-fluid {
        > .navbar-collapse {
            //β版実装時に削除
            flex-direction: row-reverse !important;

            a {
                color: black;
                transition: opacity 0.3s;

                &:hover {
                    opacity: 0.7;
                }
            }
        }

        > .navbar-toggler {
            border: none;
        }

        > .navbar-toggler:focus {
            box-shadow: none !important;
        }
    }

    .o7_button {
        background: none;
        color: black;
        border: none;
        &:active{
            background: none;
            color: black;
            border: none;
        }
        @media screen and (max-width: 767px) {
            padding-left: 0;
        }
    }
}

@media (min-width: 768px) {
    .o7_navbar {
        height: 45px !important;
        box-shadow:
            0px 1px 3px 1px rgba(0, 0, 0, 0.15),
            0px 1px 2px 0px rgba(0, 0, 0, 0.3);
        > .container-fluid {
            height: 45px !important;
        }
    }
}

@media (max-width: 767px) {
    .o7_navbar {
        > .container-fluid {
            width: 100%;
            // height: 45px;
            box-shadow:
                0px 1px 3px 1px rgba(0, 0, 0, 0.15),
                0px 1px 2px 0px rgba(0, 0, 0, 0.3);
        }
    }
}

.m10_nav {
    > .nav-link {
        color: color.$Neutral10;
        margin-inline: 5px;

        &:nth-last-child(1) {
            margin-right: 0;
        }

        &:not(:nth-last-child(1)):hover,
        .a1_usericon:hover {
            opacity: 0.7;
        }
    }
}

@media screen and (max-width: 767px) {
    .m10_nav {
        > .nav-link {
            color: color.$Neutral10;
            margin: 0;
        }
    }

    //     .m10_nav {
    //         >.nav-link {
    //             margin: 0;

    //             &:nth-child(2)::after {
    //                 content: "受信ボックス";
    //                 display: block;
    //             }

    //             &:nth-child(3)::after {
    //                 content: "お知らせ";
    //                 display: block;
    //             }
    //         }
    //     }
}
