@use "../../styles/settings/_FontFamily.scss" as font_family;
@use "../../styles/settings/_Color.scss" as color;



.a6_form {
	display: flex;
	margin-bottom: 16px;
	align-items: flex-end;

	>.icon {
		min-width: 60px;
		margin-right: 22px;

		>img {
			height: 40px;
		}
	}

	>.col_form {
		width: 100%;
		margin-right: 12px;

		>.form_label {
			font-family: 'AtkinsonHyperlegible-Bold';
		}

		>.input_group {
			border-radius: 0.3rem;
			background-color: color.$Neutral80;
			outline: color.$Neutral80 solid 2.5px;

			@media screen and (max-width: 700px) {
				flex-direction: column;

				>input:not([placeholder="ＵＲＬを入力"]) {
					margin: 0 auto !important;
					border-top-left-radius: 0 !important;
					border-top-right-radius: 0 !important;
					border-bottom-right-radius: var(--bs-border-radius) !important;
					border-bottom-left-radius: var(--bs-border-radius) !important;
				}

				>input {
					width: 100% !important;
				}
			}

			>.input_label {
				font-family: 'AtkinsonHyperlegible-Bold';
				background-color: color.$Neutral80;
				border: none;
			}
		}
	}

	>.check_label {
		height: 38px;
		text-wrap: nowrap !important;
		display: flex;
		align-items: center;

		>input {
			margin-right: 8px;
		}

		>label {
			user-select: none;
		}
	}
}