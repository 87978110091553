//PrimaryColor
$Primary0: #000000;
$Primary10: #001B3C;
$Primary20: #003060;
$Primary30: #004788;
$Primary40: #005EB2;
$Primary50: #2C77D1;
$Primary60: #4D92ED;
$Primary70: #75ADFF;
$Primary80: #A7C8FF;
$Primary90: #D5E3FF;
$Primary95: #EBF1FF;
$Primary99: #FDFBFF;
$Primary100: #FFFFFF;

//Secondary_Color
$Secondary0: #000000;
$Secondary10: #121C2B;
$Secondary20: #273141;
$Secondary30: #3D4758;
$Secondary40: #555F71;
$Secondary50: #6E778A;
$Secondary60: #8791A5;
$Secondary70: #A2ABC0;
$Secondary80: #BDC7DC;
$Secondary90: #D9E3F8;
$Secondary95: #EBF1FF;
$Secondary99: #FDFBFF;
$Secondary100: #FFFFFF;

//Tertiary_Color
$Tertiary0: #000000;
$Tertiary10: #27132F;
$Tertiary20: #3E2845;
$Tertiary30: #553E5D;
$Tertiary40: #6E5676;
$Tertiary50: #886E8F;
$Tertiary60: #A387AA;
$Tertiary70: #BFA2C5;
$Tertiary80: #DBBCE1;
$Tertiary90: #F8D8FE;
$Tertiary95: #FEEBFF;
$Tertiary99: #FDFBFF;
$Tertiary100: #FFFFFF;

//Error_Color
$Error0: #000000;
$Error10: #410002;
$Error20: #690005;
$Error30: #93000A;
$Error40: #BA1A1A;
$Error50: #DE3730;
$Error60: #FF5449;
$Error70: #FF897D;
$Error80: #FFB4AB;
$Error90: #FFDAD6;
$Error95: #FFEDEA;
$Error99: #FDFBFF;
$Error100: #FFFFFF;

//Neutral_Color
$Neutral0: #000000;
$Neutral4: #0D0E11;
$Neutral6: #121316;
$Neutral10: #1A1C1E;
$Neutral12: #1E2023;
$Neutral17: #292A2D;
$Neutral20: #2F3033;
$Neutral22: #343538;
$Neutral24: #38393C;
$Neutral30: #46474A;
$Neutral40: #5E5E62;
$Neutral50: #76777A;
$Neutral60: #909094;
$Neutral70: #ABABAF;
$Neutral80: #C7C6CA;
$Neutral87: #DAD9DD;
$Neutral90: #E3E2E6;
$Neutral92: #E9E7EB;
$Neutral94: #EEEDF1;
$Neutral95: #F1F0F4;
$Neutral96: #F4F3F7;
$Neutral98: #FAF9FD;
$Neutral99: #FDFBFF;
$Neutral100: #FFFFFF;

//Neutral_Variant_Color
$Neutral_Variant0: #000000;
$Neutral_Variant10: #181C22;
$Neutral_Variant20: #2D3038;
$Neutral_Variant30: #43474E;
$Neutral_Variant40: #5B5E66;
$Neutral_Variant50: #74777F;
$Neutral_Variant60: #8E9199;
$Neutral_Variant70: #A8ABB4;
$Neutral_Variant80: #C4C6CF;
$Neutral_Variant90: #E0E2EC;
$Neutral_Variant92: #E6E8F1;
$Neutral_Variant95: #EEF0FA;
$Neutral_Variant99: #FDFBFF;
$Neutral_Variant100: #FFFFFF;

//Other_Color
$Pink10: #FC617D;
$Green30:#56E47E;
$Green10: #00B232;
$Green20: #389400;
