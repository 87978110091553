@use "../../../styles/settings/_Color.scss" as color;

.m43_radio {
    &.error>.form-check-input {
        border-color: color.$Error50;
    }

    >.form-check-input {
        margin: 0;
        width: 24px;
        height: 24px;
        border: solid 3px color.$Neutral20;

        &:focus {
            box-shadow: none;
        }

        &:hover {
            box-shadow: 0px 0px 0px 4px color.$Neutral90;
            background-color: color.$Neutral90;
        }

        &:checked {
            filter: invert(1) grayscale(90%) brightness(100%);
            background-color: black;
            border: solid 3px color.$Neutral80;

            &:hover {
                box-shadow: 0px 0px 0px 4px color.$Neutral10;
                background-color: color.$Neutral10;
            }
        }
    }

    .form-check-label {
        font-size: 14px;
        margin-left: 10px;
    }
}