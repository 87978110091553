@use "../../../styles/settings/_Color.scss" as color;
@use "../../../styles/settings/FontFamily" as font-family;

.o14_container {
	>p {
		margin: 2px 0;
	}
	>p:first-of-type {
		margin-top: 20px;
	}

	>div {
		margin-top: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;

		> h2 {
			font-family: "BIZUDGothic-Bold";
			font-size: 16px;
		}

		p {
			font-size: 14px;
			margin-bottom: 0;
		}
		> ul {
			margin-left: 16px;
		}
	}
	>.o14_link_box {
		font-size: 12px;
		a {
			font-family: 'BIZUDGothic-Bold';
			color: color.$Primary30;
		}
	}
}
