@use "../../../styles/settings/_Color.scss" as color;
@use "../../../styles/settings/_FontFamily.scss" as font-family;

.m29_card {
  width: 100%;
  height: 130px;
  display: flex;
  font-family: "BIZUDGothic-Regular";

  > .m29_thumbnail {
    width: 180px;
    height: 130px;
    object-fit: cover;
    margin-right: 20px;
  }

  > .m29_container {
    > .m29_genre {
      color: color.$Neutral50;
      font-size: 12px;
      font-weight: 400;
      line-height: normal;
      margin: 0;
      margin-bottom: 8px;
    }

    > .m29_project_title {
      margin: 0;
      font-size: 20px;
      font-weight: 700;
      line-height: normal;
      color: color.$Neutral20;
      margin-bottom: 6px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
    }

    > .m29_user_box {
      display: flex;
      align-items: center;
      height: 33px;
      margin-bottom: 4px;

      > .m29_user_icon {
        width: 24px;
        height: 24px;
        object-fit: cover;
        border-radius: 100px;
        margin-right: 9px;
      }

      > .m29_attribute_box {
        > .m29_attribute {
          margin: 0;
          font-size: 10px;
          color: color.$Neutral50;
          font-size: 10px;
        }

        > .m29_name {
          margin: 0;
          font-size: 14px;
          font-family: "BIZUDGothic-Bold";
        }
      }
    }

    > .m29_text {
      width: 100%;
      font-size: 12px;
      margin: 0;
      color: color.$Neutral20;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;

      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      font-weight: 400;
      line-height: 16px;
    }
  }

  .m29_pseudo_function {
    > .m29_props {
      > .m29_bookmark_button {
        margin-top: 8px;
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        border-radius: 10px;
        border: none;
        background: rgba(color.$Primary40, 0.2);

        path {
          fill: color.$Primary40;
        }
      }

      > .m29_like_button {
        margin-top: 8px;
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        border-radius: 10px;
        border: none;
        background: rgba(color.$Pink10, 0.2);

        path {
          fill: color.$Pink10;
        }
      }
    }
  }
}
