@use "../../styles/settings/_Color.scss" as color;

.p6_container {
	>.create_account {
		width: 246px;
		margin-left: auto;
		margin-right: auto;
	}

	>a {
		text-align: right;
		font-family: "BIZUDGothic-Bold";
		font-size: 12px;
		margin-top: 7px;

        &:hover {
            color: color.$Neutral30 !important;
        }

        &:active {
            color: color.$Neutral20 !important;
        }
	}
}
