@use "../../../styles/settings/_Color.scss" as color;

.o41_modal {
    >.modal-dialog {
        >.modal-content {
            min-width: 380px;

            .modal-header {
                border-bottom: none !important;
                justify-content: space-between;

                >.back_btn_hidden {
                    visibility: hidden;
                }

                >.back_btn {
                    display: block;
                    width: 40px !important;
                    height: 40px !important;
                    border-radius: 50%;
                    background-color: color.$Neutral90;
                    border: none;
                    padding: 0;

                    &:hover {
                        background: color.$Neutral80;
                    }

                    &:active {
                        background: color.$Neutral70 !important;
                    }
                }

                >p {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0;
                    font-size: 20px !important;
                    font-family: 'BIZUDGothic-Bold';
                }

                >.modal_close_div {
                    display: flex;
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    background-color: color.$Error40;
                    flex-direction: column;
                    justify-content: space-evenly;
                    align-items: center;
                    border: none;

                    >.close_btn {
                        padding: 0;
                        margin: 0 10px;
                        height: 16px;
                        width: 16px;
                        opacity: 1;

                        &:focus {
                            box-shadow: none !important;
                        }
                    }

                    &:hover {
                        opacity: .7;
                    }

                    &:focus {
                        opacity: .7;
                    }
                }
            }
        }
    }
}
