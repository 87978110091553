//single_Toggleのスタイリング
//スタイリング担当者：アミン
.a8_toggle_input {
    .form-check-input {
        width: 50px;
        height: 24px;
        background-color: #DEDEDE;
        box-shadow: inset 0px 3px 9px 0px rgba(51, 51, 51, 0.4);
        border-radius: 50px;
        transition: background-position .3s ease;
        --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");

        &:focus {
            --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
            border-color: #fff;
            outline: 0;
            box-shadow: inset 0px 3px 9px 0px rgba(51, 51, 51, 0.4);
        }

        &:checked {
            background-position: right center;
            background-color: #76E5A8;
            border-color: #fff;
            transition: .3s ease;
        }
        &:active {
            filter: none;
        }
    }
}
