@use "../../styles/settings/_Color.scss" as color;

.m10_container {
    width: 965px;

    >.m10_first_row {
        >.m10_second_col {
            >button {
                width: 200px;
                height: 42px;
                color: color.$Error40;
                background-color: color.$Error100;
                border-color: color.$Error40;
                font-family: "BIZUDGothic-Bold";
                font-size: 16px !important;


                &:hover {
                    color: color.$Error100;
                    background-color: color.$Error40;
                }

                &:active {
                    color: color.$Error100;
                    background-color: color.$Error30;
                }
            }
        }
    }

    >.m10_second_row {
        >.m10_third_col {
            >.a7_box {
                margin-top: 6px;
            }
        }

        >.m10_fourth_col {

            //アカウント削除ボタン
            >button {
                width: 200px;
                height: 42px;
                background-color: color.$Error40;
                font-family: "BIZUDGothic-Bold";
                font-size: 16px !important;

                &:hover {
                    background-color: color.$Error30;
                }

                &:active {
                    background-color: color.$Error20;
                }
            }

            //注意書き
            >.attention {
                margin-top: 5px;
                color: color.$Error40;
            }

        }
    }
}
