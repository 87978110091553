@use "../../styles/settings/_Color.scss" as color;
.indexedStep {
    color: color.$Neutral_Variant100;
    width: 48px;
    height: 48px;
    font-size: 30px;
    background-color: color.$Neutral_Variant90;
    border-radius: 50%;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(206, 206, 206);
    display: flex;
    justify-content: center;
    align-items: center;
}

.indexedStep.accomplished {
    background-color: color.$Green30;
    color: white;
    border-style: none;
    > svg {
        opacity: 0;
        animation: a10_check 0.6s ease-in-out 0.2s forwards;
        width: 40px;
        height: 40px;
    }
    path {
        fill: #fff;
    }
}
.RSPBprogressBar {
    height: 5px;
    width: 50%;
    line-height: 1;
    border-radius: 10px;
    position: relative;
    background-color: rgb(207, 207, 207);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 0;
    margin: 20px auto 40px auto;
}

.RSPBprogressBar .RSPBstep {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
    position: absolute;
    transform: translateX(-50%);
    transition-property: all;
    transition-timing-function: ease;
}

.RSPBprogressBar .RSPBprogressBarText {
    color: white;
    font-size: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.RSPBprogressBar .RSPBprogression {
    position: absolute;
    transition: width 0.3s ease;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 10px;
    background: color.$Green30;
    z-index: -1;
}

@media screen and (max-width: 480px) {
    .indexedStep {
        width: 30px;
        height: 30px;
        font-size: 25px;
    }
}

@keyframes a10_check {
    0% {
        transform: scale(0.2) rotate(30deg);
        opacity: 0;
    }
    50% {
        transform: scale(0.1) rotate(15deg);
        opacity: 0.7;
    }
    90% {
        transform: scale(1.3) rotate(-15deg);
        opacity: 1;
    }
    100% {
        transform: scale(1) rotate(0deg);
        opacity: 1;
    }
}
