@use "../../styles/settings/_Color.scss" as color;

.m11 {
	background-color: color.$Neutral99 !important;
	border-color: color.$Primary40 !important;
	color: color.$Primary40;
	font-family: "BIZUDGothic-Bold";
	display: flex;
	justify-content: center;
	align-items: center;
	transition: none;
	width: fit-content !important;
	min-width: 200px;
	visibility: hidden;

	* {
		transition: none;
	}

	> svg g path{
		fill: color.$Neutral99 !important;
	}

	&:hover {
		color: color.$Neutral70 !important;
		background: color.$Primary40 !important;

		> svg {
			// filter: brightness(0) invert(1);

			> g path {
				fill: color.$Neutral70 !important;
			}
		}
	}

	&:active {
		color: color.$Neutral60 !important;
		background: color.$Primary30;

		> svg {
			// filter: brightness(0) invert(1);

			> g path {
				fill: color.$Neutral60 !important;
			}
		}
	}
	// &:disabled {
	// 	color: color.$Neutral50;
	// 	>svg {
	// 		path {
	// 			fill: color.$Neutral50 !important;
	// 		}
	// 	}
	// }
	&.show {
		visibility: unset !important;
	}
}