@use "../../../styles/settings/_Color.scss" as color;
@use "../../../styles/settings/Setting" as setting;

.p28_container {
	@media screen and (max-width: setting.$sp_width) {
		margin: 0;
		padding: 0;
		width: 100%;
		max-width: none;
	}

	@media screen and (min-width: setting.$pc_width) {
		margin: 30px auto;
		align-items: center;
	}

	flex: 1;
	display: flex;

	>.p28_row {
		flex-direction: column;
		background-color: #FAF9FD;
		width: 500px;
		padding: 30px;
		border-radius: 15px;
		box-shadow: 0px 1px 3px 1px rgba(color.$Primary0, 0.15),
			0px 1px 2px 0px rgba(color.$Primary0, 0.3);

		@media screen and (max-width: setting.$sp_width) {
			width: 100%;
			border-radius: 0px;
			margin: 0;
			min-height: 100%;
		}

		>progress {
			width: 261px;
			height: 47px;
			margin-left: auto;
			margin-right: auto;
		}

		>.a7_box {
			margin-left: auto;
			margin-right: auto;
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: fit-content;
			align-items: center;

			>.o1_wapper {
				margin-bottom: 33px;
			}

			>svg,
			>img {
				width: 48px;
				height: 48px;
				margin-bottom: 33px;
			}

			>label {
				font-family: 'BIZUDGothic-Bold';
				font-size: 20px;
			}
		}

		>section,>form {
			display: flex;
			flex-direction: column;

			>p {
				font-size: 14px;
				text-align: center;
			}

			>.a7_box {
				margin-top: 27px;
			}

			>.m39_select {
				margin-top: 5px;
			}

			>input {
				margin-top: 5px;
				background: #E6E8F1;
				border: 1px solid #C4C6CF;
				box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.25);
				border-radius: 3px;
				height: 33px;
			}

			>.btn {
				margin-top: 26px;
				width: 245px;
				height: 56px;
				margin-left: auto;
				margin-right: auto;
				filter: drop-shadow(1px 1px 2px color.$Neutral50);
                border: none !important;
                background-color: color.$Primary40 !important;

                &:hover {
                    background: color.$Primary30 !important;
                }

                &:active {
                    background: color.$Primary20 !important;
                }
			}

			>.page_buttons {
				margin-top: 30px;
				display: flex;
				justify-content: space-between;

				>.btn {
					width: 162px;
					height: 56px;
					margin-left: auto;
					margin-right: auto;
					background-color: #005EB2;
					border-color: #005EB2;
				}

				>.btn:first-of-type {
					background-color: #fff;
					color: #005EB2;
					border-width: 2px;
				}
			}
		}

		>.btn {
			border: none;
			background: color.$Primary40;
			width: 260px;
			height: 60px;
			box-shadow: 0px 1px 3px 0px rgba(color.$Neutral10, 0.25);
			display: block;
			margin: 60px auto;

			&:hover {
				background: color.$Primary30;
			}

			&:active {
				background: color.$Primary20 !important;
			}
		}

		.btn {
			font-family: 'BIZUDGothic-Bold';
		}
	}
}
