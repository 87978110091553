@use "../../styles/settings/_Color.scss" as color;
@use "../../styles/settings/_FontFamily.scss" as fontfamily;

.m14_editor_container {
    max-width: 989px;
    margin: 50px auto;

    > .m14_text_counter {
        font-family: AtkinsonHyperlegible-Regular;
    }

    .other h2 {
        font-size: 18px;
        color: color.$Neutral_Variant30;
        margin-bottom: 7px;
    }

    .other a {
        color: color.$Neutral50;
        text-decoration: underline;
        font-size: 14px;
    }

    .other ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    .App {
        font-family: sans-serif;
        text-align: center;
    }

    .ltr {
        text-align: left;
    }

    .rtl {
        text-align: right;
    }

    .editor-container {
        margin: 20px auto 20px auto;
        border-radius: 2px;
        color: color.$Neutral10;
        position: relative;
        line-height: 20px;
        font-weight: 400;
        text-align: left;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .editor-inner {
        min-height: 800px;
        background: color.$Neutral99;
        position: relative;
    }

    .editor-input {
        min-height: 80vh;
        resize: none;
        font-size: 15px;
        position: relative;
        tab-size: 1;
        outline: 0;
        padding: 15px 28px;
        caret-color: color.$Neutral_Variant50;
    }

    .editor-placeholder {
        color: color.$Neutral60;
        overflow: hidden;
        position: absolute;
        text-overflow: ellipsis;
        top: 15px;
        left: 10px;
        font-size: 15px;
        -webkit-user-select: none;
        user-select: none;
        display: inline-block;
        pointer-events: none;
        margin-left: 19px;
    }

    .editor-text-bold {
        font-weight: bold;
    }

    .editor-text-italic {
        font-style: italic;
    }

    .editor-text-underline {
        text-decoration: underline;
    }

    .editor-text-strikethrough {
        text-decoration: line-through;
    }

    .editor-text-underlineStrikethrough {
        text-decoration: underline line-through;
    }

    .editor-text-code {
        background-color: color.$Neutral90;
        padding: 1px 0.25rem;
        font-family: Menlo, Consolas, Monaco, monospace;
        font-size: 94%;
    }

    .editor-link {
        color: color.$Primary40;
        text-decoration: none;
    }

    .tree-view-output {
        display: block;
        background: color.$Neutral10;
        color: color.$Neutral99;
        padding: 5px;
        font-size: 12px;
        white-space: pre-wrap;
        margin: 1px auto 10px auto;
        max-height: 250px;
        position: relative;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        overflow: auto;
        line-height: 14px;
    }

    .editor-code {
        background-color: color.$Neutral90;
        font-family: Menlo, Consolas, Monaco, monospace;
        display: block;
        padding: 8px 8px 8px 52px;
        line-height: 1.53;
        font-size: 13px;
        margin: 0;
        margin-top: 8px;
        margin-bottom: 8px;
        tab-size: 2;
        overflow-x: auto;
        position: relative;
    }

    .editor-code:before {
        content: attr(data-gutter);
        position: absolute;
        background-color: color.$Neutral94;
        left: 0;
        top: 0;
        border-right: 1px solid color.$Neutral_Variant80;
        padding: 8px;
        color: color.$Neutral50;
        white-space: pre-wrap;
        text-align: right;
        min-width: 25px;
    }

    .editor-code:after {
        content: attr(data-highlight-language);
        top: 0;
        right: 3px;
        padding: 3px;
        font-size: 10px;
        text-transform: uppercase;
        position: absolute;
        color: color.$Neutral10;
    }

    .editor-tokenComment {
        color: slategray;
    }

    .editor-tokenPunctuation {
        color: #999;
    }

    .editor-tokenProperty {
        color: #905;
    }

    .editor-tokenSelector {
        color: #690;
    }

    .editor-tokenOperator {
        color: #9a6e3a;
    }

    .editor-tokenAttr {
        color: #07a;
    }

    .editor-tokenVariable {
        color: #e90;
    }

    .editor-tokenFunction {
        color: #dd4a68;
    }

    .editor-paragraph {
        margin: 0;
        // margin-bottom: 8px;
        position: relative;
    }

    .editor-paragraph:last-child {
        margin-bottom: 0;
    }

    .editor-heading-h1 {
        font-size: 24px;
        color: color.$Neutral10;
        font-weight: 400;
        margin: 0;
        margin-bottom: 12px;
        padding: 0;
    }

    .editor-heading-h2 {
        font-size: 15px;
        color: color.$Neutral_Variant40;
        font-weight: 700;
        margin: 0;
        margin-top: 10px;
        padding: 0;
        text-transform: uppercase;
    }

    .editor-quote {
        margin: 0;
        margin-left: 20px;
        font-size: 15px;
        color: color.$Neutral_Variant50;
        border-left-color: color.$Neutral_Variant70;
        border-left-width: 4px;
        border-left-style: solid;
        padding-left: 16px;
    }

    .editor-list-ol {
        padding: 0;
        margin: 0;
        margin-left: 16px;
    }

    .editor-list-ul {
        padding: 0;
        margin: 0;
        margin-left: 16px;
    }

    .editor-listitem {
        margin: 8px 32px 8px 32px;
    }

    .editor-nested-listitem {
        list-style-type: none;
    }

    pre::-webkit-scrollbar {
        background: transparent;
        width: 10px;
    }

    pre::-webkit-scrollbar-thumb {
        background: color.$Neutral60;
    }

    .debug-timetravel-panel {
        overflow: hidden;
        padding: 0 0 10px 0;
        margin: auto;
        display: flex;
    }

    .debug-timetravel-panel-slider {
        padding: 0;
        flex: 8;
    }

    .debug-timetravel-panel-button {
        padding: 0;
        border: 0;
        background: none;
        flex: 1;
        color: color.$Neutral99;
        font-size: 12px;
    }

    .debug-timetravel-panel-button:hover {
        text-decoration: underline;
    }

    .debug-timetravel-button {
        border: 0;
        padding: 0;
        font-size: 12px;
        top: 10px;
        right: 15px;
        position: absolute;
        background: none;
        color: color.$Neutral99;
    }

    .debug-timetravel-button:hover {
        text-decoration: underline;
    }

    .toolbar {
        overflow-x: auto;
        position: sticky;
        top: 45px;
        z-index: 10;
        display: flex;
        font-family: AtkinsonHyperlegible-Regular;
        background: color.$Primary90;
        padding: 4px;
        vertical-align: middle;
    }

    .toolbar button.toolbar-item {
        border: 0;
        display: flex;
        background: none;
        border-radius: 10px;
        padding: 8px;
        cursor: pointer;
        vertical-align: middle;
    }

    .toolbar button.toolbar-item:disabled {
        cursor: not-allowed;
    }

    .toolbar button.toolbar-item.spaced {
        margin-right: 2px;
    }

    .toolbar button.toolbar-item i.format {
        background-size: contain;
        display: inline-block;
        height: 18px;
        width: 18px;
        margin-top: 2px;
        vertical-align: -0.25em;
        display: flex;
        opacity: 0.6;
    }

    .toolbar button.toolbar-item:disabled i.format {
        opacity: 0.2;
    }

    .toolbar button.toolbar-item.active {
        background-color: color.$Primary70;
    }

    .toolbar button.toolbar-item.active i {
        opacity: 1;
    }

    .toolbar .toolbar-item:hover:not([disabled]) {
        background: color.$Primary80;
    }

    .toolbar .divider {
        width: 1px;
        background-color: color.$Neutral94;
        margin: 0 4px;
    }

    .toolbar select.toolbar-item {
        border: 0;
        display: flex;
        background: none;
        border-radius: 10px;
        padding: 8px;
        vertical-align: middle;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 70px;
        font-size: 14px;
        color: color.$Neutral50;
        text-overflow: ellipsis;
    }

    .toolbar select.code-language {
        text-transform: capitalize;
        width: 130px;
    }

    select.code-language {
        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
            background: color.$Neutral90;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background: color.$Neutral80;
            border-radius: 10px;

            &:hover {
                background: color.$Neutral70;
            }

            &:active {
                background: color.$Neutral60;
            }
        }
    }

    .toolbar select.code-language:focus {
        outline: none;
    }

    .toolbar select.code-language option {
        background-color: color.$Neutral99;
    }

    .toolbar .toolbar-item .text {
        display: flex;
        line-height: 20px;
        width: 200px;
        vertical-align: middle;
        font-size: 14px;
        color: color.$Neutral_Variant50;
        text-overflow: ellipsis;
        width: 70px;
        overflow: hidden;
        height: 20px;
        text-align: left;
    }

    .toolbar .toolbar-item .icon {
        display: flex;
        width: 20px;
        height: 20px;
        -webkit-user-select: none;
        user-select: none;
        margin-right: 8px;
        margin-bottom: 0;
        line-height: 16px;
        background-size: contain;
    }

    .toolbar i.chevron-down {
        margin-top: 3px;
        width: 16px;
        height: 16px;
        display: flex;
        -webkit-user-select: none;
        user-select: none;
    }

    .toolbar i.chevron-down.inside {
        width: 16px;
        height: 16px;
        display: flex;
        margin-left: -25px;
        margin-top: 11px;
        margin-right: 10px;
        pointer-events: none;
    }

    i.chevron-down {
        background-color: transparent;
        background-size: contain;
        display: inline-block;
        height: 8px;
        width: 8px;
        background-image: url(../../components/../assets/chevron-down.svg);
    }

    #block-controls button:hover {
        background-color: color.$Neutral_Variant95;
    }

    #block-controls button:focus-visible {
        border-color: color.$Primary40;
    }

    #block-controls span.block-type {
        background-size: contain;
        display: block;
        width: 18px;
        height: 18px;
        margin: 2px;
    }

    #block-controls span.block-type.paragraph {
        background-image: url(../../components/../assets/text-paragraph.svg);
    }

    #block-controls span.block-type.h1 {
        background-image: url(../../components/../assets/type-h1.svg);
    }

    #block-controls span.block-type.h2 {
        background-image: url(../../components/../assets/type-h2.svg);
    }

    #block-controls span.block-type.quote {
        background-image: url(../../components/../assets/chat-square-quote.svg);
    }

    #block-controls span.block-type.ul {
        background-image: url(../../components/../assets/list-ul.svg);
    }

    #block-controls span.block-type.ol {
        background-image: url(../../components/../assets/list-ol.svg);
    }

    #block-controls span.block-type.code {
        background-image: url(../../components/../assets/code.svg);
    }

    i.undo {
        background-image: url(../../components/../assets/arrow-counterclockwise.svg);
    }

    i.redo {
        background-image: url(../../components/../assets/arrow-clockwise.svg);
    }

    .icon.paragraph {
        background-image: url(../../components/../assets/text-paragraph.svg);
    }

    .icon.large-heading,
    .icon.h1 {
        background-image: url(../../components/../assets/type-h1.svg);
    }

    .icon.small-heading,
    .icon.h2 {
        background-image: url(../../components/../assets/type-h2.svg);
    }

    .icon.bullet-list,
    .icon.ul {
        background-image: url(../../components/../assets/list-ul.svg);
    }

    .icon.numbered-list,
    .icon.ol {
        background-image: url(../../components/../assets/list-ol.svg);
    }

    .icon.quote {
        background-image: url(../../components/../assets/chat-square-quote.svg);
    }

    .icon.code {
        background-image: url(../../components/../assets/code.svg);
    }

    i.bold {
        background-image: url(../../components/../assets/type-bold.svg);
    }

    i.italic {
        background-image: url(../../components/../assets/type-italic.svg);
    }

    i.underline {
        background-image: url(../../components/../assets/type-underline.svg);
    }

    i.strikethrough {
        background-image: url(../../components/../assets/type-strikethrough.svg);
    }

    i.code {
        background-image: url(../../components/../assets/code.svg);
    }

    i.link {
        background-image: url(../../components/../assets/link.svg);
    }

    i.left-align {
        background-image: url(../../components/../assets/text-left.svg);
    }

    i.center-align {
        background-image: url(../../components/../assets/text-center.svg);
    }

    i.right-align {
        background-image: url(../../components/../assets/text-right.svg);
    }

    i.justify-align {
        background-image: url(../../components/../assets/justify.svg);
    }
}
label.toolbar-item {
    border: 0;
    display: flex;
    background: none;
    border-radius: 10px;
    padding: 8px;
    cursor: pointer;
    vertical-align: middle;
    > i.image-add,i.youtube-add {
        background-size: contain;
        height: 18px;
        width: 18px;
        margin-top: 2px;
        vertical-align: -0.25em;
        opacity: 0.6;
    }
    >i.image-add{
        background-image: url(../../components/../assets/image_icon.svg);
    }
    > i.youtube-add {
        background-image: url(../../components/../assets/sns/youtube.png);
        background-repeat: no-repeat;
        background-position: center;
    }
    > input, button#toolbarPlubin-youtube-insert{
        opacity: 0;
        width: 0;
        height: 0;
    }
    > button#toolbarPlubin-youtube-insert{
        display: none;
    }
}

.m14_button {
    border: none;
    background: color.$Primary40;
    width: 260px;
    height: 60px;
    box-shadow: 0px 1px 3px 0px rgba(color.$Neutral10, 0.25);
    display: block;
    margin: 60px auto;

    &:hover {
        background: color.$Primary30;
    }

    &:active {
        background: color.$Primary20 !important;
    }
}

.m14_button_edit{
    margin-top: 60px;
    margin-bottom: 60px;
}

.m14_button_del{
        border: none;
        display: block;
        margin: 60px auto;
        width: 260px;
        height: 60px;
        border: solid 1px color.$Error50;
}

.m14_to_top_button {
    width: clamp(38px, 10%, 57px);
    background-color: color.$Primary40;
    opacity: 0;
    pointer-events: none;
    aspect-ratio: 1/1;
    z-index: 10;
    right: 2.5%;
    bottom: 5%;
    border-radius: 5px;
    transition: all 0.25s ease-in-out;

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 25%;
        aspect-ratio: 1/1;
        border-top: 3px solid color.$Neutral99;
        border-right: 3px solid color.$Neutral99;
        transform: translate(-50%, -35%) rotate(-45deg);
    }

    &:hover {
        background-color: color.$Primary30;
    }

    &:active {
        background-color: color.$Primary20 !important;
    }
}

.m14_to_top_button_show {
    opacity: 1;
    pointer-events: auto;
}

.dropdown {
    z-index: 5;
    display: block;
    position: fixed;
    font-family: AtkinsonHyperlegible-Regular;
    box-shadow:
        0 12px 28px 0 rgba(color.$Neutral0, 0.2),
        0 2px 4px 0 rgba(color.$Neutral0, 0.1),
        inset 0 0 0 1px rgba(color.$Neutral100, 0.5);
    border-radius: 8px;
    min-width: 100px;
    min-height: 40px;
    background-color: color.$Neutral98;
}

.dropdown .item {
    margin: 0 8px 0 8px;
    padding: 8px;
    color: color.$Neutral10;
    cursor: pointer;
    line-height: 16px;
    font-size: 15px;
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: space-between;
    background-color: color.$Neutral98;
    border-radius: 8px;
    border: 0;
    min-width: 268px;
}

.dropdown .item .active {
    display: flex;
    width: 20px;
    height: 20px;
    background-size: contain;
}

.dropdown .item:first-child {
    margin-top: 8px;
}

.dropdown .item:last-child {
    margin-bottom: 8px;
}

.dropdown .item:hover {
    background-color: color.$Neutral95;
}

.dropdown .item .text {
    display: flex;
    line-height: 20px;
    flex-grow: 1;
    width: 200px;
}

.dropdown .item .icon {
    display: flex;
    width: 20px;
    height: 20px;
    -webkit-user-select: none;
    user-select: none;
    margin-right: 12px;
    line-height: 16px;
    background-size: contain;
}

.link-editor {
    position: absolute;
    z-index: 100;
    left: 10px !important;
    margin-top: -6px;
    max-width: 300px;
    width: 100%;
    opacity: 0;
    background-color: color.$Neutral99;
    box-shadow: 0px 5px 10px rgba(color.$Neutral0, 0.3);
    border-radius: 8px;
    transition: opacity 0.5s;
}

.link-editor .link-input {
    display: block;
    width: calc(100% - 24px);
    box-sizing: border-box;
    margin: 8px 12px;
    padding: 8px 12px;
    border-radius: 15px;
    background-color: color.$Neutral95;
    font-size: 15px;
    color: color.$Neutral10;
    border: 0;
    outline: 0;
    position: relative;
    font-family: inherit;
}

.link-editor div.link-edit {
    background-image: url(../../components/../assets/pencil-fill.svg);
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
    width: 35px;
    vertical-align: -0.25em;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
}

.link-editor .link-input a {
    color: color.$Primary40;
    text-decoration: none;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 30px;
    text-overflow: ellipsis;
}

.link-editor .link-input a:hover {
    text-decoration: underline;
}

.link-editor .button {
    width: 20px;
    height: 20px;
    display: inline-block;
    padding: 6px;
    border-radius: 8px;
    cursor: pointer;
    margin: 0 2px;
}

.link-editor .button.hovered {
    width: 20px;
    height: 20px;
    display: inline-block;
    background-color: color.$Neutral_Variant95;
}

.link-editor .button i,
.actions i {
    background-size: contain;
    display: inline-block;
    height: 20px;
    width: 20px;
    vertical-align: -0.25em;
}

.dropdown .item .icon {
    display: flex;
    width: 20px;
    height: 20px;
    -webkit-user-select: none;
    user-select: none;
    margin-right: 12px;
    line-height: 16px;
    background-size: contain;
}

.toolbar .toolbar-item .icon {
    display: flex;
    width: 20px;
    height: 20px;
    -webkit-user-select: none;
    user-select: none;
    margin-right: 8px;
    line-height: 16px;
    background-size: contain;
}

.icon.paragraph {
    background-image: url("https://lmpfy0.csb.app/images/icons/text-paragraph.svg");
}

.icon.h1 {
    background-image: url("https://lmpfy0.csb.app/images/icons/type-h1.svg");
}

.icon.h2 {
    background-image: url("https://lmpfy0.csb.app/images/icons/type-h2.svg");
}

.icon.h3 {
    background-image: url("https://lmpfy0.csb.app/images/icons/type-h3.svg");
}

.icon.bullet-list,
.icon.ul {
    background-image: url("https://lmpfy0.csb.app/images/icons/list-ul.svg");
}

.icon.numbered-list,
.icon.ol {
    background-image: url("https://lmpfy0.csb.app/images/icons/list-ol.svg");
}

.icon.quote {
    background-image: url("https://lmpfy0.csb.app/images/icons/chat-square-quote.svg");
}

.icon.code {
    background-image: url("https://lmpfy0.csb.app/images/icons/code.svg");
}

.draggable-block-menu .icon {
    width: 16px;
    height: 16px;
    opacity: 0.3;
    background-image: url("https://lmpfy0.csb.app/images/icons/draggable-block-menu.svg");
}

.draggable-block-menu {
    border-radius: 4px;
    padding: 2px 1px;
    cursor: grab;
    opacity: 0;
    position: absolute;
    left: 0px;
    top: 0;
    will-change: transform;
}

.draggable-block-menu:active {
    cursor: grabbing;
}

.draggable-block-menu:hover {
    background-color: #efefef;
}

.draggable-block-target-line {
    pointer-events: none;
    background: deepskyblue;
    height: 4px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    will-change: transform;
}

.editor-paragraph * {
    // -webkit-user-select: none;
    // -moz-user-select: none;
    // user-select: none;
    -webkit-user-drag: none;
    -moz-user-drag: none;
}

.border {
    outline: solid 4px #07a;
}

.draggable {
    outline: solid 3px rgb(60, 132, 244);
}
// .image-resizer-container {
//     position: relative;
//     display: inline-block;
// }
.editor-paragraph:has(.editor-image) {
    font-size: 6px;
    caret-color: #adadad;
}
.editor-image {
    cursor: default;
    display: inline-block;
    position: relative;
    user-select: none;
    &::after {
        content: " ";
    }
}
.editor-image:has(img.focused.draggable){
    z-index: 1;
}

.image-resizer {
    display: block;
    width: 7px;
    height: 7px;
    position: absolute;
    background-color: rgb(60, 132, 244);
    border: 1px solid #fff;
}

.image-resizer.image-resizer-n {
    top: -6px;
    left: 48%;
    cursor: n-resize;
}

.image-resizer.image-resizer-ne {
    top: -6px;
    right: -6px;
    cursor: ne-resize;
}

.image-resizer.image-resizer-e {
    bottom: 48%;
    right: -6px;
    cursor: e-resize;
}

.image-resizer.image-resizer-se {
    bottom: 0px;
    right: -6px;
    cursor: nwse-resize;
}

.image-resizer.image-resizer-s {
    bottom: 2px;
    left: 48%;
    cursor: s-resize;
}

.image-resizer.image-resizer-sw {
    bottom: 0px;
    left: -6px;
    cursor: sw-resize;
}

.image-resizer.image-resizer-w {
    bottom: 48%;
    left: -6px;
    cursor: w-resize;
}

.image-resizer.image-resizer-nw {
    top: -6px;
    left: -6px;
    cursor: nw-resize;
}
