@use "../../../styles/settings/FontFamily" as font_family;
@use "../../../styles/settings/Color" as color;

.o45_modal_body {
    padding-top: 0;
    padding-bottom: 36px;
    font-family: "BIZUDGothic-Bold";
    > .o45_attention_content {
        margin: 0 auto;
        height: 96px;
        width: 335px;
        background-color: color.$Error90;
        border: solid 1px color.$Error50;
        border-radius: 5px;
        color: color.$Error40;
        margin-bottom: 30px;
        > svg {
            width: 48px;
            height: 48px;
            margin-right: 15px;
            path {
                fill: color.$Error40;
            }
        }
        > p {
            margin-bottom: 0;
            font-size: 16px !important;
        }
    }

    > .o45_stack {
        justify-content: center;

        > .btn {
            min-width: 110px !important;
            font-size: 14px !important;
        }
    }
}
