@use "../../../styles/settings/FontFamily";
@use "../../../styles/settings/_Color.scss" as color;

.m28_card {
    display: block;
    text-decoration: none;
    width: 256px;
    max-width: 256px;
    height: 320px;
    transition: 0.2s;
    @media screen and (max-width: 560px) {
        width: 100%;
        max-width: none;
        height: 150px;
    }
    // @media screen and (min-width: 880px) {
    //     max-width: 256px;
    //     &:hover{
    //         transform: translateY(-1em);
    //     }
    // }
    > .card {
        --bs-card-border-width: 0 !important;
        width: 100%;
        height: 100%;
        -moz-box-shadow: 0 4px 4px 0 rgba(color.$Neutral0, 0.25);
        -webkit-box-shadow: 0 4px 4px 0 rgba(color.$Neutral0, 0.25);
        -ms-box-shadow: 0 4px 4px 0 rgba(color.$Neutral0, 0.25);
        box-shadow: 0 4px 4px 0 rgba(color.$Neutral0, 0.25);
        border-radius: 8px;
        transition: 0.2s;
        @media screen and (max-width: 560px) {
            flex-direction: row;
            &:active{
                background: color.$Neutral90;
            filter: none;
            }
        }
        > a{
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
        }
        > .delete_button {
            background-color:  color.$Error40;
            position: absolute;
            border-radius: 50%;
            border: none;
            width: 24px;
            height: 24px;
            right: -8px;
            top: -6px;
            > svg {
                position: absolute;
                top: 2px;
                left: 2px;
                height: 20px;
                width: 20px;
            }
            path {
                fill: color.$Primary100;
            }
        }
        > img {
            width: 100%;
            height: 144px;
            object-fit: cover;
            @media screen and (max-width: 560px) {
                width: 152px;
                height: 100%;
                border-radius: unset;
            }
        }

        > .card-body {
            padding: 8px 16px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            > h1 {
                font-family: "BIZUDGothic-Bold";
                color: color.$Neutral_Variant30;
                font-size: 16px;
                margin: 0;
            }

            >div{
                display: flex;
                flex-direction: column;
                gap: 8px;
                position: absolute;
                bottom: 8px;
                z-index: 2;
                
                > .tools {
                    display: flex;
                    width: fit-content;
                    align-items: center;
                    height: 14px;
                    margin: 0;
    
                    > * {
                        font-family: "AtkinsonHyperlegible-Regular";
                        margin-left: 4px;
                        font-size: 12px;
                    }
    
                    > .tool_tile {
                        display: flex;
                        align-items: center;
                        height: 16px;
                        width: fit-content;
                        padding: 0 5px;
                        background-color: color.$Primary40;
                        color: color.$Primary100;
                        line-height: 150%;
                        border-radius: 15px;
                        text-decoration: none;
                        text-wrap: nowrap;
                    }
    
                    > .tool_tile:first-of-type {
                        margin-left: 0;
                    }
    
                    > p {
                        color: color.$Neutral_Variant30;
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                }
    
                > .user {
                    text-decoration: none;
                    display: flex;
                    margin: 0;
    
                    > img {
                        width: 18px;
                        height: 18px;
                        border-radius: 50%;
                    }
    
                    > p {
                        margin: 0;
                        margin-left: 2px;
                        font-family: "AtkinsonHyperlegible-Regular";
                        font-size: 12px;
                        color: color.$Neutral_Variant30;
                    }
                }
    
                > .other {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
    
                    > .project_progress {
                        width: 5em;
                        display: flex;
                        align-items: center;
    
                        > p {
                            margin: 0;
                            padding: 0 1em;
                            font-family: "AtkinsonHyperlegible-Regular";
                            font-size: 12px;
                            background-color: color.$Primary40;
                            color: color.$Neutral100;
                            border-radius: 4px;
                        }
                    }
                    > .end {
                        > span {
                            background: color.$Error40;
                        }
                        > p {
                            color: color.$Error40;
                        }
                    }
    
                    > .right_menu {
                        display: flex;
                        align-items: center;
                        margin-right: 16px;
                        gap: 10px;
    
                        > button {
                            display: flex;
                            align-items: center;
                            width: 24px;
                            height: 24px;
                            background-color: transparent;
                            border: none;
                            cursor: pointer;
                            outline: none;
                            padding: 0;
                            appearance: none;
                            path {
                                fill: color.$Neutral_Variant30;
                            }
                        }
    
                        > .like {
                            display: flex;
                            align-items: center;
                            overflow-wrap: break-word;
    
                            > p {
                                margin: 0;
                                margin-left: 5px;
                                font-family: "AtkinsonHyperlegible-Regular";
                                font-size: 12px;
                                color: color.$Neutral50;
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }
    }
}
