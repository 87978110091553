@use "../../styles/settings/_Color.scss" as color;

.o6_footer {
  background-color: color.$Neutral95;
  height: 125px;
  font-size: 12px;
  border-top: solid 2px color.$Neutral87;
  > div {
    margin-bottom: 8px;
    column-gap: 12px;
    > img {
      width: 50px;
      height: 50px;
    }
  }
  .o6_nav {
    > a {
      color: color.$Neutral10;
      &:hover {
        color: color.$Neutral60;
      }
      &:not(:nth-last-child(1))::before {
        transform: translateY(-42%);
        content: "";
        width: 1px;
        height: 20px;
        background-color: color.$Neutral10;
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .o6_footer {
    .o6_nav {
      > a {
        &:not(:nth-last-child(1))::before {
          display: none;
        }
      }
    }
  }
}
