@use "../../styles/settings/_Color.scss" as color;

.m16_wrapper {
  > .m16_input_label_wrapper {
    width: 965px;
    padding: 50px 0;
    border-bottom: 1px solid rgba(color.$Neutral_Variant80, 0.5);
    > label {
      width: 215px;
    }
    > .a7_box {
      width: 215px;
      margin-left: 0;
    }
  }
  > button {
    border: none;
    background: color.$Primary40;
    width: 260px;
    height: 60px;
    box-shadow: 0px 1px 3px 0px rgba(color.$Neutral10, 0.25);
    display: block;
    margin: 60px auto;
    &:hover {
      background: color.$Primary30;
    }
    &:active {
      background: color.$Primary20 !important;
    }
  }
}
