@use "../../../styles/settings/_Color.scss" as color;
@use "../../../styles/settings/FontFamily" as fontFamily;

.o32_container {
	>a {
		font-family: 'BIZUDGothic-Bold';
		text-align: right;
		width: 100%;
		font-size: 12px;
		margin-top: 7px;
	}

	>.validation {
		display: flex;
	}

	>.agree_box {
		width: 100%;
		margin-top: 24px;
		display: flex;
		align-items: center;
		justify-content: center;

		>input {
			background: color.$Neutral20;
			margin-right: 7px;
		}

		>p {
			font-family: 'BIZUDGothic-Bold';
			font-size: 14px;
			margin: 0;

			a {
				color: color.$Primary40;
			}
		}
	}
}