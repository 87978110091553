@use "../../styles/settings/_FontFamily.scss" as font_family;
@use "../../styles/settings/_Color.scss" as color;

.m20_container {
    padding: 0 4%;


    .m20_button {
        padding: auto;
        filter: drop-shadow(1px 1px 2px color.$Neutral20);
        border: none !important;
        width: 260px;
        height: 60px;
        background-color: color.$Primary40;
        font-size: 16px !important;

        &:hover {
            background: color.$Primary30;
        }

        &:active {
            background: color.$Primary20 !important;
        }

        &:focus {
            border: none !important;
            box-shadow: none !important;
        }

        >.m20_button_text {
            font-size: 14px;
        }
    }

    >hr {
        margin-bottom: 40px;
    }

    .item {
        display: flex;
        align-items: center;
        padding: 0 5%;
        min-height: 42px;
        margin-bottom: 40px;

        >.a7_box {
            width: 37%;
            text-wrap: nowrap;
            align-items: center;
            max-width: 200px;

            >img {
                margin-bottom: 0;
            }
        }

        >p {
            margin-right: 1.5%;
        }

        >.input_text,
        >.m25_text {
            width: 50%;
        }

        >input {
            background: color.$Neutral_Variant95;
            border: 2px solid color.$Neutral_Variant80;
            border-radius: 5px;

            &:focus-visible {
                outline: auto color.$Primary40 !important;
                border-radius: 4px;
            }
        }

        >.calender {
            position: relative;
            display: inline-block;
            width: 250px;
            height: 45px;
            background: color.$Neutral_Variant95;
            border: 2px solid color.$Neutral_Variant80;
            border-radius: 5px;

            &::before {
                position: absolute;
                content: "";
                top: 0;
                right: 0px;
                width: 40px;
                height: 40px;
                background-color: color.$Neutral_Variant90;
                background-image: url("../../assets/calendar_add_on\ .svg");
                background-repeat: no-repeat;
                background-position: center;
                border-radius: 50%;
            }

            >input[type="date"],
            input[type="month"] {

                position: relative;
                padding: 0 10px;
                width: 246px;
                height: 41px;
                border: 0;
                background: transparent;
                box-sizing: border-box;
                font-size: 14px;
                color: #646464;

                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                }

                &::-webkit-clear-button {
                    -webkit-appearance: none;
                }

                &::-webkit-calendar-picker-indicator {
                    position: absolute;
                    right: 0px;
                    top: 0px;
                    padding: 0;
                    width: 40px;
                    height: 40px;
                    background: transparent;
                    color: transparent;
                    cursor: pointer;

                    &:hover {
                        background-color: color.$Neutral50;
                        border-radius: 50%;
                        opacity: 0.2;
                    }
                }
            }
        }
    }
}