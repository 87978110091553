@use "../../../styles/settings/_Color.scss" as color;

.p18_title {
  font-size: 24px;
  margin-top: 65px;
}

.p18_preamble {
  font-size: 14px;
}

.p18_form_wrapper {
  .error {
    border-color: color.$Error50;
  }

  > div {
    &:not(:first-child) {
      > .a7_box {
        margin-top: 1.5rem;
      }
    }

    > .a7_box {
      margin-bottom: 0.5rem;
    }

    > input {
      min-height: 45px;
      height: 100%;
      border-radius: 3px;
      border: 2px solid color.$Neutral_Variant80;
      background: color.$Neutral_Variant92;
      box-shadow: 0px 1px 3px 0px rgba(26, 28, 30, 0.25) inset;

      &:hover,
      &:focus {
        border: 2px solid color.$Primary40;
        background: color.$Neutral_Variant92;
        box-shadow: 0px 1px 3px 0px rgba(26, 28, 30, 0.25) inset;
      }

      &:focus::placeholder {
        color: transparent;
      }

      &::placeholder {
        color: rgba(color.$Neutral10, 0.5);
      }
    }

    > textarea {
      border-radius: 3px;
      border: 2px solid color.$Neutral_Variant80;
      background: color.$Neutral_Variant92;
      box-shadow: 0px 1px 3px 0px rgba(26, 28, 30, 0.25) inset;

      &:hover,
      &:focus {
        border: 2px solid color.$Primary40;
        background: color.$Neutral_Variant92;
        box-shadow: 0px 1px 3px 0px rgba(26, 28, 30, 0.25) inset;
      }
    }
  }

  > button {
    border: none;
    background: color.$Primary40;
    width: 260px;
    height: 60px;
    box-shadow: 0px 1px 3px 0px rgba(color.$Neutral10, 0.25);
    display: block;
    margin: 60px auto;

    &:hover {
      background: color.$Primary30;
    }

    &:active {
      background: color.$Primary20 !important;
    }
  }
}
