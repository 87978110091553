@use "../../styles/settings/_Color.scss" as color;
@use "../../styles/settings/FontFamily" as font_family;

@keyframes o13_anim {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }

    20% {
        transform: translateX(0);
        opacity: 1;
    }

    75% {
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        transform: translateX(100%);
        opacity: 0;
    }
}

.o13_pop_window {
    width: 30%;
    border-radius: 5px;
    position: fixed;
    height: 60px;
    bottom: 0;
    right: 0;
    margin: 0 20px 20px 0;
    z-index: 10000;
    background-color: color.$Tertiary99;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    animation: 3s ease-out both o13_anim;

    > .left_color {
        background-color: color.$Green10;
        width: 10px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    > .red {
        background-color: color.$Error40 !important;
    }

    > .o13_check {
        > svg {
            width: 36px;
            height: 36px;
            line-height: 28px;
            margin: auto 12px;

            path {
                fill: color.$Green10;
            }
        }

        > .red {
            path {
                fill: color.$Error40;
            }
        }
    }

    > p {
        font-family: "AtkinsonHyperlegible-Regular";
        font-size: 14px;
        margin: auto 0;
        line-height: 20px;
    }
}
