@use "../../styles/settings/_FontFamily.scss" as font_family;
@use "../../styles/settings/_Color.scss" as color;

.m45 {
	display: flex;
	font-family: "BIZUDGothic-Bold";
	font-size: 13px;
	margin-top: 4px;
	align-items: center;
	margin-right: 6px;

	>p {
		color: color.$Error50;
		margin: 0 1px;
		text-wrap: nowrap;
	}

	>.checked {
		color: #00B232 !important;
	}

	>img {
		width: 16px;
	}
}