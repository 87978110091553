@use "../../../styles/settings/_Color.scss" as color;

.a7_required {
	background-color: color.$Error40;
	border-radius: 3px;
}

.a7_any {
	background-color: color.$Neutral_Variant30;
	border-radius: 3px;
}

.a7_img {
	width: 24px;
	height: 24px;
	margin-bottom: 10px;

	>* {
		margin-bottom: 10px;
	}
}

.a7_label {
	font-weight: bold;
	font-size: 14px;
	vertical-align: middle;
	margin-left: 5px;
}