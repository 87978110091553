@use "../../styles/settings/_Color.scss" as color;
@use "../../styles/settings/_FontFamily.scss" as font-family;

.p9_main_container {
    padding: 120px 20px 0 20px;

    > .btn-primary {
        filter: drop-shadow(1px 1px 2px color.$Neutral20);
        border: none !important;
        background-color: color.$Primary40 !important;

        &:hover {
            background: color.$Primary30 !important;
        }

        &:active {
            background: color.$Primary20 !important;
        }

        &:focus {
            border: none !important;
            box-shadow: none !important;
        }
    }

    @media screen and (max-width: 800px) {
        padding-top: 40px;
    }

    > .back_img {
        margin-top: 45px;
        top: 0;
        left: 0;
        position: absolute;
        z-index: -1;
        width: calc(100vw - calc(100vw - 100%));
        height: 145px;
        object-fit: cover;
        border-radius: 0.375rem 0.375rem 0 0;
        overflow: hidden;

        > img {
            width: 100%;
            filter: blur(12px);
            transform: translateY(-45%);
        }
    }

    > .p9_user_info {
        margin-bottom: 40px;
        .p9_user_mypage {
            img {
                width: 160px;
                height: 160px;
                border-radius: 50%;
                margin-right: 40px;
                object-fit: cover;
                @media screen and (max-width: 800px) {
                    margin: 0 auto;
                    margin-bottom: 20px;
                }
            }
            @media screen and (max-width: 800px) {
                flex-direction: column;
            }
            .p9_name_info {
                margin: 0;
                margin-top: auto;
                @media screen and (max-width: 800px) {
                    display: flex;
                    align-items: flex-end;
                    margin: 0 auto;
                }
                > .name_list > h4 {
                    font-family: "AtkinsonHyperlegible-Bold", "BIZUDGothic-Bold";
                }
                > svg {
                    padding-left: 4%;
                }
                path {
                    fill: color.$Primary100;
                }
                > .share_button {
                    width: 120px;
                    filter: drop-shadow(1px 1px 1px color.$Neutral20);
                    border: none !important;
                    background-color: color.$Primary40 !important;

                    &:hover {
                        background: color.$Primary30 !important;
                    }

                    &:active {
                        background: color.$Primary20 !important;
                    }

                    &:focus {
                        border: none !important;
                        box-shadow: none !important;
                    }
                }
            }
        }
        @media screen and (max-width: 800px) {
            flex-direction: column;
            align-items: unset !important;
        }
    }

    .p9_edit_button {
        width: 250px;
        height: 50px;
        filter: drop-shadow(1px 1px 1px color.$Neutral20);
        border: none !important;
        background-color: color.$Primary40 !important;

        &:hover {
            background: color.$Primary30 !important;
        }

        &:active {
            background: color.$Primary20 !important;
        }

        &:focus {
            border: none !important;
            box-shadow: none !important;
        }

        @media screen and (max-width: 800px) {
            margin-top: 10px;
        }
    }

    > .o2_tabs {
        border: none;
    }
}
