@use "../../../styles/settings/_Color.scss" as color;

.m47_modal_body {

    .m47_create,
    .m47_add {
        height: 300px !important;
        margin-top: 40px;

        >.m47_svg {
            height: 120px !important;
            margin-bottom: 20px;
        }
    }

    svg {
        filter: contrast(0.6)
    }

    @media screen and (max-width: 992px) {
        .m47_line {
            border-top: solid 3px color.$Neutral60;
            width: 80%;
            margin: 0 auto;
        }
    }

    @media screen and (min-width: 993px) {
        .m47_line {
            border-right: solid 3px color.$Neutral60;
            height: 300px;
            width: 1px;
        }
    }

    .m47_btn,
    .m47_link {
        width: 260px;
        height: 60px;
        background-color: color.$Primary40;
        font-size: 16px !important;
        border: none;

        &:hover {
            background: color.$Primary30;
        }

        &:active {
            background: color.$Primary20 !important;
        }
    }

    .m47_link {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: color.$Primary100;
    }
}
