@use "../../styles/settings/_Color.scss" as color;
@use "../../styles/settings/_FontFamily.scss" as font_family;

.m19_container {

    >.item {
        >.m19_desired_box {
            height: 180px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            >p {
                margin-bottom: 0px !important;
                font-size: 12px;
                color: color.$Error40;
                font-family: "BIZUDGothic-Bold";
            }

            input {
                &:focus-visible {
                    outline: none !important;
                }
            }
        }
    }

    >.submit_area {
        width: 100%;
        display: flex;
        justify-content: center;

        >.btn {
            padding: auto;
            filter: drop-shadow(1px 1px 2px color.$Neutral20);
            border: none !important;
            width: 260px;
            height: 60px;
            background-color: color.$Primary40;
            font-size: 16px !important;

            &:hover {
                background: color.$Primary30;
            }

            &:active {
                background: color.$Primary20 !important;
            }

            &:focus {
                border: none !important;
                box-shadow: none !important;
            }

            >.m20_button_text {
                font-size: 14px;
            }
        }
    }
}