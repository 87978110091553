@use "../../../styles/settings/_Color.scss" as color;
.o2_title{
	margin: 0 auto;
	margin-top: 65px;
	font-family: "BIZUDGothic-Bold";
	text-align: center;
}
.o2_tabs {
	width: 100%;
	padding: 15px 0;
	border-bottom: 1px solid color.$Neutral_Variant80;
	margin-top: 30px;
	> :not(.o2_active_tab) {
		opacity: 0.7;
	}

	>.o2_tab {
		white-space: nowrap;
		background: transparent;
		border: none;
		border-radius: 5px;
		padding: 12px 60px;
		white-space: nowrap;
		font-weight: bold;
		color: color.$Neutral20;
		transition: all .25s ease-in;
		&:not(:nth-last-child(1)) {
			margin-right: 100px;
		}

		&:hover {
			background: color.$Primary90;
			color: color.$Primary40;
		}

		&:active {
			background: color.$Primary80;
		}
	}

	>.o2_active_tab {
		background: color.$Primary90;
		color: color.$Primary40;
	}
}