@use "../../styles/settings/Color" as color;
@use "../../styles/settings/_FontFamily.scss" as font-family;

.p3_lp_container {
    background-color: color.$Primary100;
    color: color.$Primary10;

    >section:not(:first-child) {
        @media screen and (max-width: 980px) {
            margin-top: 80px;
        }
    }

    .p3_common_link {
        display: block;
        width: 268px;
        height: 49px;
        text-align: center;
        line-height: 49px;
        background-color: color.$Primary40;
        color: color.$Primary100;
        font-family: BIZUDGothic-Bold;
        border-radius: 5px;
        border: none;
        box-shadow: 0px 20px 50px rgba(color.$Primary40, 0.3);

        &:hover {
            background-color: color.$Primary30;
            transition: background-color 0.3s;
        }

        &:active {
            background-color: color.$Primary20;
        }

        @media screen and (max-width: 980px) {
            display: block;
            margin: 0 auto;
        }
    }

    // ヒーローセクション
    .p3_hero {
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: calc(100vh - 45px);

        .p3_left_content {
            font-family: BIZUDGothic-Bold;

            .p3_faro_logo {
                width: clamp(320px, 40vw, 340px);

                svg {
                    width: 100%;
                    height: 100%;
                }
            }

            h1 {
                margin-top: 30px;
                margin-bottom: 50px;
                font-size: clamp(20px, 2vw, 40px);

                @media screen and (max-width: 980px) {
                    padding-left: 20px;
                }
            }

            ul {
                list-style-type: none;
                margin-bottom: 50px;
                font-size: clamp(16px, 1vw, 20px);
                padding-left: 0px;

                li {
                    margin: 8px 0;
                    display: flex;
                    align-items: center;

                    p {
                        margin: 0;
                        margin-left: 10px;
                    }
                }

                @media screen and (max-width: 980px) {
                    padding-left: 20px;
                }
            }
        }

        .p3_right_content {
            height: 100%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            gap: 30px;

            @media screen and (max-width: 980px) {
                display: none;
            }

            .p3_loop_animation {
                display: flex;
                flex-direction: column;

                &:last-child {
                    flex-direction: column-reverse;
                }

                div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-start;

                    span {
                        display: block;
                        width: 256px;
                        height: 300px;
                        margin: 10px 0;
                        background-color: #06c755;

                        &:nth-child(odd) {
                            background-color: skyblue;
                        }
                    }
                }

                // トップページの上下アニメーション

                .p3_infinite_rise {
                    animation: moveUpwards 20s linear infinite;
                }

                @keyframes moveUpwards {
                    from {
                        transform: translateY(0);
                    }

                    to {
                        transform: translateY(-100%);
                    }
                }

                .p3_infinite {
                    animation: moveDownwards 20s linear infinite;
                }

                @keyframes moveDownwards {
                    from {
                        transform: translateY(0);
                    }

                    to {
                        transform: translateY(100%);
                    }
                }
            }
        }
    }

    .p3_step {
        @media screen and (max-width: 980px) {
            background: #d5e3ff;
            padding: 15px 0;
        }

        div {
            height: 100vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            background: linear-gradient(0deg,
                    color.$Primary100 0%,
                    color.$Primary90 10%,
                    color.$Primary90 90%,
                    color.$Primary100 100%);

            @media screen and (max-width: 980px) {
                margin: auto;
                padding: 10px 0;
                width: calc(100% - 30px);
                height: 100%;
                background: white;
                border-radius: 5px;
            }

            ul {
                padding: 0;
                display: flex;
                justify-content: center;
                gap: 3em;
                z-index: 2;

                @media screen and (max-width: 980px) {
                    display: block;
                }
            }

            h1 {
                font-size: clamp(20px, 2vw, 22px);
                font-weight: bold;
                padding: 10px;

                span:first-child {
                    font-size: clamp(22px, 2vw, 50px);
                    font-family: AtkinsonHyperlegible-Bold;
                    color: color.$Primary40;
                }

                span:last-child {
                    font-size: clamp(18px, 2vw, 50px);
                    color: color.$Primary40;
                }
            }

            .p3_fade_animation {
                opacity: 0;
                list-style: none;
                width: 25%;
                transition: opacity 1s ease;
                padding-bottom: 10px;

                &:nth-child(1) {
                    transition-delay: 1.1s;
                }

                &:nth-child(2) {
                    transition-delay: 1.2s;
                }

                &:nth-child(3) {
                    transition-delay: 1.3s;
                }

                @media screen and (max-width: 980px) {
                    width: 100%;
                }

                h2 {
                    font-size: 22px;
                    font-family: AtkinsonHyperlegible-Regular;
                    color: color.$Primary40;
                }

                >.p3_light_svg {
                    transform: rotate(180deg);
                }

                svg {
                    width: 70px;
                }

                h3 {
                    font-size: clamp(16px, 2vw, 22px);
                    font-family: BIZUDGothic-Bold;
                }

                p {
                    font-size: clamp(14px, 2vw, 16px);
                    line-height: 35px;

                    @media screen and (max-width: 980px) {
                        padding: 0 40px;
                    }

                    span {
                        font-size: 20px;
                        font-weight: bold;
                    }
                }
            }

            .p3_slide_animation {
                opacity: 1;
            }
        }
    }

    .p3_make_project_introduction {
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 100vh;

        @media screen and (max-width: 980px) {
            height: 100%;
        }

        .p3_left_project_introduction {
            width: 40%;
            font-family: BIZUDGothic-Bold;

            @media screen and (max-width: 980px) {
                width: 100%;
            }

            h1 {
                font-size: clamp(22px, 2vw, 35px);

                @media screen and (max-width: 980px) {
                    text-align: center;
                    padding: 20px 0;
                }
            }

            img {
                @media screen and (max-width: 980px) {
                    width: 100%;
                    padding: 30px;
                    display: block;
                }

                @media screen and (min-width: 980px) {
                    width: 40%;
                    display: none;
                }
            }

            p {
                margin-top: 50px;
                margin-bottom: 30px;
                font-size: clamp(14px, 2vw, 16px);

                @media screen and (max-width: 980px) {
                    margin: 0 auto;
                    padding: 40px 20px;
                    text-align: center;
                }

                span {
                    font-size: 22px;
                }
            }
        }

        .p3_right_project_introduction {
            width: 40%;

            @media screen and (max-width: 980px) {
                display: none;
            }

            img {
                width: 100%;
                box-shadow: 0 50px 100px -20px rgba(#696969, 0.8);

                @media screen and (max-width: 980px) {
                    display: none;
                }
            }
        }
    }

    .p3_faro_introduction {
        >h1 {
            font-size: clamp(24px, 5vw, 32px);
            font-weight: 800;
            text-align: center;
            z-index: 10;
            position: relative;

            @media screen and (max-width: 980px) {
                margin-bottom: 60px;
            }

            span {
                color: color.$Primary40;
                font-family: AtkinsonHyperlegible-Regular;
                font-size: clamp(34px, 8vw, 90px);

                @media screen and (max-width: 980px) {
                    color: color.$Primary10;
                }
            }
        }

        section {
            display: flex;
            align-items: center;
            justify-content: space-around;
            height: 100vh;

            @media screen and (max-width: 980px) {
                height: auto;
                margin-bottom: 20px;
            }
        }

        .p3_introduction_idea {
            >div:first-child {
                width: 40%;
                z-index: 100;

                @media screen and (max-width: 980px) {
                    display: none;
                }

                img {
                    width: 100%;
                }
            }

            >div:nth-of-type(2) {
                width: 100%;
                height: 100%;
                position: absolute;

                @media screen and (max-width: 980px) {
                    display: none;
                }

                span {
                    width: clamp(200px, 5vw, 400px);
                    border-radius: 50%;
                    filter: blur(50px);
                    aspect-ratio: 1/1;
                    display: block;
                    position: absolute;
                    transform: translate(-50%, -50%);
                }

                span:first-child {
                    background: radial-gradient(circle,
                            rgb(55, 0, 255),
                            rgba(55, 0, 255, 0));
                    top: 50%;
                    left: 20%;
                    animation: rotate1 10s linear infinite;

                    @keyframes rotate1 {
                        0% {
                            scale: 100%;
                        }

                        50% {
                            scale: 150%;
                        }

                        100% {
                            scale: 100%;
                        }
                    }
                }

                >span:nth-of-type(2) {
                    background: radial-gradient(circle,
                            rgb(0, 255, 229),
                            rgba(0, 255, 229, 0));
                    top: 50%;
                    left: 30%;
                    animation: rotate2 15s linear infinite;

                    @keyframes rotate2 {
                        0% {
                            scale: 100%;
                        }

                        70% {
                            scale: 150%;
                        }

                        100% {
                            scale: 100%;
                        }
                    }
                }

                >span:nth-of-type(3) {
                    background: radial-gradient(circle,
                            rgb(123, 126, 255),
                            rgba(123, 126, 255, 0));
                    top: 35%;
                    left: 30%;
                    animation: rotate3 20s linear infinite;

                    @keyframes rotate3 {
                        0% {
                            scale: 90%;
                        }

                        40% {
                            scale: 150%;
                        }

                        100% {
                            scale: 90%;
                        }
                    }
                }

                >span:last-child {
                    background: radial-gradient(circle,
                            rgb(0, 255, 242),
                            rgba(0, 255, 242, 0));
                    top: 70%;
                    left: 20%;
                    animation: rotate4 25s linear infinite;

                    @keyframes rotate4 {
                        0% {
                            scale: 100%;
                        }

                        90% {
                            scale: 150%;
                        }

                        100% {
                            scale: 100%;
                        }
                    }
                }
            }

            >div:last-of-type {
                width: 40%;
                height: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                position: relative;
                overflow: hidden;

                @media screen and (max-width: 980px) {
                    width: calc(100% - 48px);
                    padding: 18px 20px;
                    box-shadow: 0 1px 2px rgba(color.$Neutral0, 0.3);
                    box-shadow: 0 1px 3px 1px rgba(color.$Neutral0, 0.15);
                }

                div {
                    h1 {
                        z-index: 10;
                        position: relative;
                        font-family: AtkinsonHyperlegible-Regular;
                        color: color.$Primary10;
                        font-weight: 700;
                        font-size: clamp(18px, 4vw, 48px);

                        @media screen and (max-width: 980px) {
                            margin-bottom: 0;
                        }
                    }

                    h2 {
                        color: color.$Primary10;
                        font-weight: 600;
                        margin: 30px 0;
                        font-size: clamp(20px, 4vw, 22px);

                        @media screen and (max-width: 980px) {
                            margin: 12px 0 24px;
                        }
                    }

                    p {
                        color: color.$Secondary10;
                        font-weight: 400;

                        @media screen and (max-width: 980px) {
                            margin-bottom: 0;
                        }
                    }
                }

                span {
                    position: absolute;
                    width: 1px;
                    height: 100%;
                    background-color: color.$Neutral40;
                    right: 0;

                    @media screen and (max-width: 980px) {
                        display: none;
                    }
                }
            }
        }

        .p3_introduction_portfolio {
            flex-direction: row-reverse;

            >div:first-child {
                width: 40%;
                box-shadow: 0 50px 100px -20px rgba(#696969, 0.8);

                @media screen and (max-width: 980px) {
                    display: none;
                }

                img {
                    width: 100%;
                }
            }

            >div:last-child {
                width: 40%;
                height: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                position: relative;
                overflow: hidden;

                @media screen and (max-width: 980px) {
                    width: calc(100% - 48px);
                    padding: 18px 20px;
                    box-shadow: 0 1px 2px rgba(color.$Neutral0, 0.3);
                    box-shadow: 0 1px 3px 1px rgba(color.$Neutral0, 0.15);
                }

                div {
                    padding-left: 30px;

                    @media screen and (max-width: 980px) {
                        padding: 0;
                    }

                    h1 {
                        color: color.$Primary90 90%;
                        z-index: 10;
                        position: relative;
                        font-family: AtkinsonHyperlegible-Regular;
                        color: color.$Primary10;
                        font-weight: 700;
                        font-size: clamp(18px, 4vw, 48px);

                        @media screen and (max-width: 980px) {
                            margin-bottom: 0;
                        }
                    }

                    h2 {
                        color: color.$Primary10;
                        font-weight: 600;
                        margin: 30px 0;
                        font-size: clamp(20px, 4vw, 22px);

                        @media screen and (max-width: 980px) {
                            margin: 12px 0 24px;
                        }
                    }

                    p {
                        color: color.$Secondary10;
                        font-weight: 400;

                        @media screen and (max-width: 980px) {
                            margin-bottom: 0;
                        }
                    }
                }

                span {
                    position: absolute;
                    width: 1px;
                    height: 100%;
                    background-color: color.$Neutral40;

                    @media screen and (max-width: 980px) {
                        display: none;
                    }
                }
            }
        }

        .p3_introduction_share {
            >div:first-child {
                position: relative;

                @media screen and (max-width: 980px) {
                    display: none;
                }

                img {
                    position: relative;
                }

                span {
                    position: absolute;
                    width: 80px;
                    aspect-ratio: 1/1;
                    background-color: rgba(#eb46c6, 0.2);
                    box-shadow: 0 0 30px 40px rgba(#eb46c6, 0.2);
                    border-radius: 100%;
                    top: 90%;
                    left: 30%;
                    animation: pink-shadow ease-in-out 5s infinite alternate-reverse;

                    @keyframes pink-shadow {
                        0% {
                            transform: translate(-15%, -20%);
                        }

                        100% {
                            transform: translate(15%, 20%);
                        }
                    }

                    &::before {
                        content: "";
                        width: 100px;
                        aspect-ratio: 1/1;
                        background-color: rgba(#ff0000, 0.2);
                        position: absolute;
                        transform: translate(-50%, -50%);
                        border-radius: 100%;
                        box-shadow: 0 0 30px 40px rgba(#ff0000, 0.2);
                        bottom: 270%;
                        right: 100%;
                        animation: red-shadow ease-in-out 4s infinite alternate-reverse;

                        @keyframes red-shadow {
                            0% {
                                transform: translate(-55%, -50%);
                            }

                            100% {
                                transform: translate(-5%, -65%);
                            }
                        }
                    }

                    &::after {
                        content: "";
                        width: 100px;
                        aspect-ratio: 1/1;
                        background-color: rgba(#06c755, 0.2);
                        position: absolute;
                        transform: translate(-50%, -50%);
                        border-radius: 100%;
                        box-shadow: 0 0 30px 40px rgba(#06c755, 0.2);
                        bottom: 350%;
                        left: 250%;
                        animation: green-shadow ease-in-out 3s infinite alternate-reverse;

                        @keyframes green-shadow {
                            0% {
                                transform: translate(75%, -50%);
                            }

                            100% {
                                transform: translate(-5%, -35%);
                            }
                        }
                    }
                }
            }

            div:last-child {
                width: 40%;
                height: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                position: relative;
                overflow: hidden;

                @media screen and (max-width: 980px) {
                    width: calc(100% - 48px);
                    padding: 18px 20px;
                    box-shadow: 0 1px 2px rgba(color.$Neutral0, 0.3);
                    box-shadow: 0 1px 3px 1px rgba(color.$Neutral0, 0.15);
                }

                div {
                    h1 {
                        z-index: 10;
                        position: relative;
                        font-family: AtkinsonHyperlegible-Regular;
                        color: color.$Primary10;
                        font-weight: 700;
                        font-size: clamp(18px, 4vw, 48px);

                        @media screen and (max-width: 980px) {
                            margin-bottom: 0;
                        }
                    }

                    h2 {
                        color: color.$Primary10;
                        font-weight: 600;
                        margin: 30px 0;
                        font-size: clamp(20px, 4vw, 22px);

                        @media screen and (max-width: 980px) {
                            margin: 12px 0 24px;
                        }
                    }

                    p {
                        color: color.$Secondary10;
                        font-weight: 400;

                        @media screen and (max-width: 980px) {
                            margin-bottom: 0;
                        }
                    }
                }

                span {
                    position: absolute;
                    width: 1px;
                    height: 100%;
                    background-color: color.$Neutral40;
                    right: 0;

                    @media screen and (max-width: 980px) {
                        display: none;
                    }
                }
            }
        }

        .p3_slide_animation_Left {
            animation: border 1s ease-in-out;

            h1 {
                transform: translateX(110%);
                animation: slideInFromLeft 1s ease-out 1s forwards;
            }

            h2 {
                transform: translateX(110%);
                animation: slideInFromLeft 1s ease-out 1s forwards;
            }

            p {
                transform: translateX(110%);
                animation: slideInFromLeft 1s ease-out 1s forwards;
            }

            @keyframes slideInFromLeft {
                0% {
                    opacity: 0;
                    transform: translateX(100%);
                }

                100% {
                    opacity: 1;
                    transform: translateX(0);
                }
            }
        }

        .p3_slide_animation_right {
            animation: border 1s ease-in-out;

            h1 {
                transform: translateX(-110%);
                animation: slideInFromRight 1.3s ease-out 1s forwards;
            }

            h2 {
                transform: translateX(-110%);
                animation: slideInFromRight 1.3s ease-out 1s forwards;
            }

            p {
                transform: translateX(-110%);
                animation: slideInFromRight 1.3s ease-out 1s forwards;
            }

            @keyframes slideInFromRight {
                0% {
                    opacity: 0;
                    transform: translateX(-110%);
                }

                50% {
                    opacity: 0.6;
                    transform: translateX(0);
                }

                100% {
                    opacity: 1;
                    transform: translateX(0);
                }
            }
        }

        @keyframes border {
            from {
                height: 0%;
            }

            to {
                height: 100%;
            }
        }
    }

    .p3_coming_soon {
        height: 50vw;
        color: color.$Neutral99;
        position: relative;
        overflow: hidden;
        background-color: #001b3c;

        @media screen and (max-width: 980px) {
            height: 100vh;
        }

        >div:first-child {
            span {
                display: block;
                position: absolute;
                width: 100vw;
                height: 50vw;
                z-index: 1;
                opacity: 0.8;
                background-color: #001b3c;
            }

            img {
                width: 100vw;
                height: 50vw;
                position: absolute;
                object-fit: cover;
                opacity: 0;
                transition: opacity 1s ease-in-out;
                transition-delay: 1.3s;
            }

            span,
            img {
                @media screen and (max-width: 980px) {
                    height: 100vh;
                }
            }

            .p3_preview_image_left,
            .p3_preview_image_right {
                opacity: 1;
            }

            .p3_preview_image_left {
                animation: preview_image_left 1.3s ease-out 1s;
            }

            @keyframes preview_image_left {
                from {
                    animation-timing-function: ease-in-out;
                    transform: translate(320px, -180px) scale(1, 1);
                }

                to {
                    animation-timing-function: linear;
                    transform: translate(0px, 0px) scale(1, 1);
                }
            }

            .p3_preview_image_right {
                animation: preview_image_right 1.3s ease-out 1s;
            }

            @keyframes preview_image_right {
                from {
                    animation-timing-function: ease-in-out;
                    transform: translate(-410px, 229px) scale(1, 1);
                }

                to {
                    animation-timing-function: linear;
                    transform: translate(0px, 0px) scale(1, 1);
                }
            }
        }

        >div:last-child {
            position: absolute;
            width: 100%;
            z-index: 2;
            top: 50%;
            transform: translateY(-50%);
            text-align: center;

            svg {
                width: clamp(60px, 10vw, 96px);
                display: block;
                margin: auto;

                g {
                    path {
                        fill: color.$Neutral99;
                    }
                }
            }

            h1 {
                font-family: AtkinsonHyperlegible-Bold;
                font-size: clamp(20px, 3vw, 40px);
            }

            h3 {
                font-size: clamp(18px, 3vw, 26px);
                padding: 0 2em;
            }

            p {
                font-size: clamp(16px, 3vw, 18px);
                padding: 0 2em;
            }
        }
    }

    .p3_start_up {
        opacity: 0;
        height: 80vh;
        transition: opacity 2s ease;

        h1 {
            font-size: clamp(20px, 3vw, 35px);
        }

        p {
            font-size: clamp(16px, 3vw, 18px);
            padding: 0 2em;
        }
    }

    .p3_start_up_animation {
        opacity: 1;
    }
}
