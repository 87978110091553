@use "../../styles/settings/_FontFamily.scss" as font_family;
@use "../../styles/settings/_Color.scss" as color;
.m12_container {
    font-family: "AtkinsonHyperlegible-Regular", "BIZUDGothic-Regular";
    > .m12_title {
        font-size: 16px;
    }
    .btn {
        width: 245px;
            filter: drop-shadow(1px 1px 1px color.$Neutral20);
            border: none !important;
            background-color: color.$Primary40 !important;

            &:hover {
                background: color.$Primary30 !important;
            }

            &:active {
                background: color.$Primary20 !important;
            }

            &:focus {
                border: none !important;
                box-shadow: none !important;
            }
    }
}
