@use "../../../styles/settings/_Color.scss" as color;

.m42_select_wrapper {
  width: 300px;
  height: 30px;
  font-size: 14px;
  &:focus {
    border: none;
    box-shadow: none;
  }
  // 非選択時
  > .css-13cymwt-control {
    height: 100%;
    min-height: 100%;
    border-radius: 3px;
    border: 2px solid color.$Neutral_Variant80;
    background: color.$Neutral_Variant95;
    box-shadow: 0px 1px 3px 0px rgba(color.$Neutral10, 0.25) inset;
    align-content: center;
    transition: border-color .15s ease-in-out;
    &:hover{
      border: 2px solid color.$Primary40;
    }
    > .css-1hb7zxy-IndicatorsContainer {
      > .css-1xc3v61-indicatorContainer {
        > svg {
          display: none;
        }
        &:after {
          content: "";
          width: 1em;
          height: 1em;
          display: block;
          background: url(../../../assets/arrow_drop_down.svg) no-repeat center
          center;
          background-size: cover;
        }
      }
    }
  }
  // 選択時
  > .css-t3ipsp-control {
    height: 100%;
    min-height: 100%;
    border: 2px solid color.$Primary40;
    background: color.$Neutral_Variant95;
    box-shadow: 0px 1px 3px 0px rgba(color.$Neutral10, 0.25) inset;
    border-radius: 3px;
    background: color.$Neutral_Variant95;
    box-shadow: none;
    align-content: center;
    &:hover{
      border: 2px solid color.$Primary40;
    }
    > .css-1hb7zxy-IndicatorsContainer {
      > .css-15lsz6c-indicatorContainer {
        > svg {
          display: none;
        }
        &:after {
          content: "";
          width: 1em;
          height: 1em;
          display: block;
          background: url(../../../assets/arrow_drop_down.svg) no-repeat center
            center;
          background-size: cover;
        }
      }
    }
  }
  > .css-1nmdiq5-menu {
    border-radius: 0;
    filter: drop-shadow(0px 1px 3px rgba(color.$Neutral0, 0.15))
      drop-shadow(0px 1px 2px rgba(color.$Neutral0, 0.3));
    > .css-1n6sfyn-MenuList {
      padding: 0;
      background: color.$Neutral98;
      > .css-d7l1ni-option {
        background: color.$Primary90;
        &:active {
          background: color.$Primary80;
        }
      }
      > .css-tr4s17-option {
        background: color.$Primary80;
        color: color.$Neutral_Variant20;
      }
      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background: color.$Neutral90;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: color.$Neutral80;
        border-radius: 10px;
        &:hover {
          background: color.$Neutral70;
        }
        &:active {
          background: color.$Neutral60;
        }
      }
    }
  }
}
