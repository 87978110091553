@use "../../../styles/settings/_Color.scss" as color;

.m31_grid,.m31_dnd_lists {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 25px;
    place-items: center;
    > div:not(.m31_create_button){
        width: 100%;
        display: flex;
        justify-content: center;
    }
    > .m31_create_button {
        width: 256px;
        height: 310px;
        border: 1px dashed #2f3033;
        border-radius: 4px;
        background: rgba(0, 0, 0, 0);
        display: flex;
        justify-content: center;
        align-items: center;
        &:after,&:before  {
            content: "";
            position: absolute;
            display: inline-block;
            background-color: #2f3033;
            z-index: -1;
        }
        &:after {
            width: 32px;
            height: 4px;
        }
        &:before {
            width: 4px;
            height: 32px;
        }
        @media screen and (max-width: 560px) {
        width: 100%;
        height: 150px;
        &:active{
            opacity: 0.6;
        }
    }
    }
}