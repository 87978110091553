.a2_wrapper {
    background: linear-gradient(92deg, #efedff 4.51%, #b4f1ff 95.45%);
    z-index: -100;

    >.a2_bubble_wrapper_xl {
        top: 47%;
        left: 38%;

        >.a2_ball {
            width: 380%;
        }
    }

    >.a2_bubble_wrapper_lg {
        top: 16%;
        left: 24%;

        >.a2_ball {
            width: 250%;
        }
    }

    >.a2_bubble_wrapper_md {
        top: 47%;
        left: 13%;

        >.a2_ball {
            width: 165%;
        }
    }

    >.a2_bubble_wrapper_sm {
        top: -4%;
        left: -7%;

        >.a2_ball {
            width: 100%;
        }
    }

    >.a2_bubble_wrapper_xs {
        top: 8%;
        left: 76%;

        >.a2_ball {
            width: 56%;
            opacity: 0.5;
        }
    }
}
