@use "../../../styles/settings/_Color.scss" as color;

.m41_select_wrapper {
  width: 750px;
  // 非選択時
  > .css-13cymwt-control {
    min-height: 45px;
    height: 100%;
    border-radius: 3px;
    border: 2px solid color.$Neutral_Variant80;
    background: color.$Neutral_Variant92;
    box-shadow: 0px 1px 3px 0px rgba(color.$Neutral10, 0.25) inset;
    > .css-3w2yfm-ValueContainer {
      align-content: center;
      > .css-1p3m7a8-multiValue {
        height: 100%;
        align-items: center;
        flex-direction: row-reverse;
        border-radius: 5px;
        background-color: color.$Primary40;
        letter-spacing: 0.05rem;
        > .css-12a83d4-MultiValueRemove {
          width: 20px;
          height: 20px;
          background-color: color.$Neutral99;
          color: color.$Primary40;
          border-radius: 10px;
          margin-left: 5px;
          transition: background-color 0.25s ease, color 0.25s ease;
          &:hover {
            background-color: color.$Error50;
            color: color.$Neutral99;
          }
        }
        > .css-wsp0cs-MultiValueGeneric {
          color: color.$Neutral99;
          font-weight: bold;
          padding: 6px 6px 6px 8px;
        }
      }
      > .css-qbdosj-Input,
      .css-166bipr-Input {
        min-height: 32px;
      }
    }
    > .css-1hb7zxy-IndicatorsContainer {
      > .css-1xc3v61-indicatorContainer:hover {
        color: color.$Error40;
      }
    }
    &:hover {
      border: 2px solid color.$Primary40;
    }
  }
  // 選択時
  > .css-t3ipsp-control {
    min-height: 45px;
    height: 100%;
    border: 2px solid color.$Primary40;
    border-radius: 3px;
    align-content: center;
    background: color.$Neutral_Variant92;
    box-shadow: 0px 1px 3px 0px rgba(color.$Neutral10, 0.25) inset;
    &:hover {
      border: 2px solid color.$Primary40;
    }
    > .css-1fdsijx-ValueContainer {
      > .css-1jqq78o-placeholder {
        color: transparent;
      }
      > .css-qbdosj-Input,
      .css-166bipr-Input {
        min-height: 32px;
      }
    }
    > .css-3w2yfm-ValueContainer {
      align-content: center;
      > .css-1p3m7a8-multiValue {
        height: 100%;
        align-items: center;
        flex-direction: row-reverse;
        border-radius: 5px;
        background-color: color.$Primary40;
        letter-spacing: 0.05rem;
        > .css-12a83d4-MultiValueRemove {
          width: 20px;
          height: 20px;
          background-color: color.$Neutral99;
          color: color.$Primary40;
          border-radius: 10px;
          margin-left: 5px;
          transition: background-color 0.25s ease, color 0.25s ease;
          &:hover {
            background-color: color.$Error50;
            color: color.$Neutral99;
          }
        }
        > .css-wsp0cs-MultiValueGeneric {
          color: color.$Neutral99;
          font-weight: bold;
          padding: 6px 6px 6px 8px;
        }
      }
      > .css-qbdosj-Input,
      .css-166bipr-Input {
        min-height: 32px;
      }
    }
    > .css-1hb7zxy-IndicatorsContainer {
      > .css-15lsz6c-indicatorContainer:hover {
        color: color.$Error40;
      }
    }
  }
  > .css-1nmdiq5-menu {
    box-shadow: none;
    border: 2px solid color.$Neutral_Variant80;
    > .css-1n6sfyn-MenuList {
      background: color.$Neutral98;
      padding: 0;
      // 非選択時
      > .css-10wo9uf-option {
        padding-left: 2rem;
        position: relative;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &::before {
          content: "";
          display: block;
          width: 1rem;
          height: 1rem;
          position: absolute;
          background: url(../../../assets/search.svg) no-repeat center center;
          background-size: cover;
          transform: translate(-50%, -50%);
          top: 50%;
          left: 1rem;
        }
        &:hover {
          background-color: color.$Primary90;
        }
        &::-webkit-scrollbar {
          width: 8px;
          height: 8px;
          background: color.$Neutral90;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
          background: color.$Neutral80;
          border-radius: 10px;
          &:hover {
            background: color.$Neutral70;
          }
          &:active {
            background: color.$Neutral60;
          }
        }
      }
      // 選択時
      > .css-d7l1ni-option {
        padding-left: 2rem;
        position: relative;
        background-color: color.$Primary90;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &::before {
          content: "";
          display: block;
          width: 1rem;
          height: 1rem;
          position: absolute;
          background: url(../../../assets/search.svg) no-repeat center center;
          background-size: cover;
          transform: translate(-50%, -50%);
          top: 50%;
          left: 1rem;
        }
        &:active {
          background-color: color.$Primary80;
        }
      }
      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background: color.$Neutral90;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: color.$Neutral80;
        border-radius: 10px;
        &:hover {
          background: color.$Neutral70;
        }
        &:active {
          background: color.$Neutral60;
        }
      }
    }
  }
}
