@use "../../../styles/settings/_Color.scss" as color;

.o26_container {
	>p {
		margin-top: 15px;
		height: 14px;
	}
	>.scout_agree {
		display: flex;
		justify-content: center;
		margin-top: 76px;
		>.btn {
			background-color: color.$Primary60;
			border-radius: 50%;
			width: 60px;
			height: 60px;
			padding: 0;
    		border: none;
			>svg {
				margin-left: auto;
				margin-right: auto;
				width: 40px;
				height: 40px;
			}
			path {
				fill: #FFF;
			}
		}
		>a {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: color.$Error60 !important;
			margin-left: 118px;
		}
	}
}