@use "../../../styles/settings/_Color.scss" as color;
@use "../../../styles/settings/_FontFamily.scss" as font-family;
.o11_password_link{
    font-size: 12px;
    color: color.$Primary30;
    font-family: 'BIZUDGothic-Bold';
}
.o11_link{
    color: color.$Primary30;
    font-family: 'BIZUDGothic-Bold';
}