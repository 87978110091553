@use "../../../styles/settings/_Color.scss" as color;

.m35_swiper {
    max-width: 2250px;

    .swiper-button-prev,
    .swiper-button-next {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background-color: rgba(color.$Neutral100, 0.7);
        box-shadow: 2px 3px 4.4px rgba(color.$Neutral0, 0.25);

        @media screen and (max-width: 768px) {
            display: none;
        }

        &::after {
            font-size: 20px;
            font-weight: bold;
            color: color.$Neutral60;
        }

        &:hover {
            background-color: #cdcdcd;
        }
    }

    .swiper-pagination-bullet {
        background-color: transparent;
        border: 1px solid color.$Primary40;
        filter: drop-shadow(0 0.5px 0.5px rgba(color.$Neutral0, 0.33));
        opacity: 1;
    }

    .swiper-pagination-bullet-active {
        background-color: color.$Primary40;
    }

    .m35_hero_project_card {
        width: clamp(395px, 50%, 720px);
        aspect-ratio: 3/2;
        overflow: hidden;
        box-shadow: 0 4px 4px rgba(color.$Neutral0, 0.25);

        .m35_hero_img {
            transition: transform 0.5s ease;
        }

        &:hover .m35_hero_img {
            transform: scale(1.1);
        }

        &:hover .m35_card_shadow::after {
            opacity: 1;
        }

        &:hover .m35_action {
            opacity: 1;
            pointer-events: auto;
        }

        .m35_card_shadow {
            background: linear-gradient(to bottom,
                    rgba(color.$Neutral0, 0) 0%,
                    rgba(color.$Neutral0, 0.33) 100%);

            &::after {
                content: "";
                width: 100%;
                height: 100%;
                display: block;
                opacity: 0;
                background: rgba(color.$Neutral0, 0.33);
                transition: opacity 0.5s ease;
            }
        }

        .m35_action,
        .m35_action_sp {
            top: 16px;
            transition: opacity 0.5s ease;

            .m35_bookmark_btn,
            .m35_share_btn,
            .m35_more_horiz_btn {
                width: clamp(48px, 5vw, 64px);
                aspect-ratio: 1/1;
                filter: drop-shadow(0 2px 2px rgba(color.$Neutral0, 0.33));

                svg {
                    width: 100%;
                    height: 100%;

                    path {
                        fill: color.$Neutral100;
                    }
                }

                transition: opacity 0.5s ease;
            }

            .m35_hidden {
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
            }
        }

        .m35_action {
            opacity: 0;
            pointer-events: none;

            @media screen and (max-width: 1024px) {
                display: none;
            }
        }

        .m35_action_sp {
            display: none;

            @media screen and (max-width: 1024px) {
                display: block;
            }
        }

        .m35_project_info {
            bottom: 24px;

            .m35_project_title {
                font-size: 24px;
                color: color.$Neutral100;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .m35_icon {
                width: 24px;
                height: 24px;
            }

            .m35_nickname {
                width: clamp(10em, 5vw, 15em);
                color: color.$Neutral100;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .m35_like_btn {
                width: clamp(48px, 5vw, 64px);
                aspect-ratio: 1/1;

                svg {
                    width: 100%;
                    height: 100%;

                    g {
                        path {
                            fill: color.$Neutral100;
                        }
                    }
                }
            }
        }
    }

    .swiper-pagination {
        position: relative;
        bottom: 0;
    }
}