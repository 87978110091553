@use "../../../styles/settings/_Color.scss" as color;

.m33_swiper {
    width: 100%;
    padding: 8px 16px;
    overflow: unset;
    max-width: calc(256px * 4 + 32px * 4);

    @media screen and (max-width: 1200px) {
        max-width: calc(256px * 3 + 32px * 3);
    }

    @media screen and (max-width: 880px) {
        height: 806px;
        max-width: 516px;
    }

    @media screen and (min-width: 880px) {
        height: 336px;
    }

    .swiper-button-prev,
    .swiper-button-next {
        width: 40px;
        height: 40px;
        background-color: color.$Neutral100;
        border-radius: 100%;
        box-shadow: 2px 3px 4.4px rgba(color.$Neutral0, 0.25);

        @media screen and (max-width: 880px) {
            display: none;
        }

        &::after {
            font-size: 20px;
            font-weight: bold;
            color: color.$Neutral60;
        }

        &:not(.swiper-button-disabled):hover {
            background-color: #cdcdcd;
        }
    }

    .swiper-button-disabled {
        pointer-events: auto !important;
    }

    .swiper-pagination {
        bottom: 88px;

        @media screen and (min-width:880px) {
            display: none;
        }

        .swiper-pagination-bullet {
            background-color: transparent;
            border: 1px solid color.$Primary40;
            filter: drop-shadow(0 0.5px 0.5px rgba(color.$Neutral0, 0.33));
            opacity: 1;
        }

        .swiper-pagination-bullet-active {
            background-color: color.$Primary40;
        }

    }

    .swiper-slide {
        height: 152px !important;

        @media screen and (min-width: 880px) {
            height: 320px !important;
        }

        @media screen and (max-width: 880px) {

            &:not(:nth-child(4n-3)) {
                margin-top: 1em !important;
            }
        }

        .m28_card {
            @media screen and (max-width: 880px) {
                width: 100%;
                max-width: none;
                height: 150px;
            }

            .card {
                @media screen and (max-width: 880px) {
                    flex-direction: row;
                }

                img {
                    @media screen and (max-width: 880px) {
                        width: 150px;
                        height: 100%;
                        border-radius: unset;
                    }
                }
            }
        }
    }

}

.m33_swiper_genre {
    @media screen and (min-width: 880px) {
        max-width: 580px;
    }

    @media screen and (min-width: 1200px) {
        max-width: 866px;
    }
}
