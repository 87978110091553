@use "../../../styles/settings/_Color.scss" as color;

.m32_category_list {
    width: 200px;
    background-color: #ebebeb;
    border-radius: 0 2em 0 0;

    @media screen and (max-width: 880px) {
        display: none !important;
    }

    @media screen and (min-width: 1200px) {
        border-radius: 2em 2em 0 0;
    }

    .m32_category_label {
        padding: 40px 0;
        color: color.$Neutral20;
    }

    .list-group-item {
        background-color: #ebebeb;
        border: none;

        &:hover:not(.active) {
            background-color: #f7f7f7;
        }
    }

    .active {
        background-color: color.$Neutral100;
        color: color.$Neutral20;
        border: none;
        box-shadow: 0 0 5px rgba(#a5a5a5, 0.25);
    }
}

.m32_category_list_sp {
    display: none !important;
    position: static !important;

    @media screen and (max-width: 880px) {
        display: flex !important;
    }

    .dropdown-toggle {
        width: 95%;
        max-width: 484px;
        border-radius: 1.5em;
        color: color.$Neutral20;
        background-color: #f2f2f2;
        box-shadow: 0 1px 2px rgba(color.$Neutral0, 0.3),
            0 1px 3px 1px rgba(color.$Neutral0, 0.15);
        outline: none;

        &::after {
            margin-left: 1em;
        }

        &:active {
            background-color: #f3f3f3;
            color: color.$Neutral20;
        }
    }

    .dropdown-menu {
        width: 484px;
        border-radius: 1.5em;
        inset: 0px auto auto 50%;
        transform: translateX(-50%);

        .dropdown-item:active {
            background-color: #f3f3f3;
            color: color.$Neutral20;
        }
    }
}

.m32_category_content {
    width: calc(100% - 200px);
    padding: 40px 0;
    overflow: hidden;

    @media screen and (max-width: 880px) {
        width: 100%;
        padding: 1em 0 1.5em;
    }

    &::before {
        content: "";
        width: 80%;
        height: 1px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: #eaeaea;

        @media screen and (max-width: 880px) {
            display: none;
        }
    }

    .m32_category_title {
        font-size: 16px;

        @media screen and (max-width: 880px) {
            display: none;
        }
    }

    .m32_more_btn {
        height: 32px;
        font-size: 12px;
        margin: 1.5em 1em 0 auto;
        background-color: color.$Primary40;
        border-color: color.$Primary40;

        @media screen and (max-width: 880px) {
            margin: -5.5em 1em 0 auto;
        }

        &:hover {
            background-color: color.$Neutral100;
            color: color.$Primary40;
            border: 2px solid color.$Primary40;

            path {
                fill: color.$Primary40;
            }
        }

        &:active {
            background-color: color.$Neutral100;
            color: color.$Primary40;
            border: 1px solid color.$Primary40;

            path {
                fill: color.$Primary40;
            }
        }

        path {
            fill: color.$Neutral100;
            transition: all 0.15s ease;
        }
    }
}