@use "../../styles/settings/_Color.scss" as color;

.p7_new_project_wrapper {
  max-width: 1200px;
  padding: 40px 0;
}

.p7_advert_image {
  width: clamp(320px, 50%, 728px);
  height: clamp(50px, 5vw, 90px);
  background-color: aliceblue;
}

.p7_category_project_wrapper {
  max-width: 1200px;
  display: flex;
  margin: 40px auto 0;

  @media screen and (max-width: 880px) {
    display: block;
  }
}

.p7_more_btn {
  width: 12em;
  height: 32px;
  font-size: 12px;
  margin: 1.5em 1em 0 auto;
  background-color: color.$Primary40;
  border-color: color.$Primary40;
  color: color.$Neutral100;
  transition: all 0.15s ease;

  &:hover {
    background-color: color.$Neutral100;
    color: color.$Primary40;
    border: 2px solid color.$Primary40;

    path {
      fill: color.$Primary40;
    }
  }

  &:active {
    background-color: color.$Neutral100;
    color: color.$Primary40;
    border: 1px solid color.$Primary40;

    path {
      fill: color.$Primary40;
    }
  }

  path {
    fill: color.$Neutral100;
    transition: all 0.15s ease;
  }
}
