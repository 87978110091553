@use "../../../styles/settings/_Color.scss" as color;

.p21_title {
  font-size: 24px;
  margin-top: 65px;
}
.p21_preamble {
  font-size: 14px;
}
.p21_article {
  > h2 {
    margin-bottom: 25px;
    font-size: 16px;
    border-left: 3px solid color.$Primary40;
  }
  > p,
  > ol {
    font-size: 14px;
  }
}
.p21_article:not(:nth-last-child(1)) {
  margin-bottom: 30px;
}
.p21_conclusion {
  font-size: 14px;
}
