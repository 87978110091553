@use "../../../styles/settings/_Color.scss" as color;
@use "../../../styles/settings/_FontFamily.scss" as fontfamily;

.p25_head_content {
    margin: 50px 0 25px;
    @media screen and (max-width: 767.9px) {
        padding: 0 20px;
    }
    .p25_user_icon {
        width: 48px;
        height: 48px;
        box-shadow:
            0px 1px 3px 1px rgba(color.$Neutral0, 0.15),
            0px 1px 2px 0px rgba(color.$Neutral0, 0.3);
    }
    .p25_user_title {
        font-size: 12px;
    }
    .p25_edit_btn {
        height: fit-content;
        background-color: color.$Neutral_Variant92;
        filter: drop-shadow(0px 1px 3px rgba(color.$Neutral0, 0.15)) drop-shadow(0px 1px 2px rgba(color.$Neutral0, 0.3));
        border-radius: 2px;
    }
}

.p25_project_title {
    font-size: 24px;
}

.p25_project_thumbnail_wrapper {
    width: clamp(300px, 50%, 600px);
    aspect-ratio: 7 / 5;
    @media screen and (max-width: 767.9px) {
        width: 95%;
    }
    .p25_project_status {
        background-color: color.$Green10;
        color: color.$Neutral99;
        font-size: 14px;
        border-radius: 5px;
        top: 0.75em;
        left: 1em;
    }
}

.p25_project_thumbnail {
    object-position: center center;
}

.p25_information_wrapper {
    width: 40%;
    @media screen and (max-width: 767.9px) {
        width: 85%;
    }
    .m13_listGroup {
        div {
            .a7_box {
                img {
                    margin: 0;
                }
            }
        }
    }
    .p25_interaction_wrapper {
        height: 36px;
        button {
            transition: all 0.2s ease;
            border: 1px solid color.$Neutral80;
            border-radius: 10px;
            &:active::after {
                transform: scale(0, 0);
                transition: 0s;
                opacity: 0.3;
            }
            svg {
                g {
                    path {
                        transition: all 0.2s ease;
                    }
                }
            }
            &:nth-of-type(n + 2) {
                width: 36px;
            }
            span {
                transition: all 0.2s ease;
                font-family: AtkinsonHyperlegible-Bold;
                color: color.$Neutral24;
            }
        }
        a {
            width: 36px;
            transition: all 0.2s ease;
            border: 1px solid color.$Neutral80;
            border-radius: 10px;
            &:active::after {
                transform: scale(0, 0);
                transition: 0s;
                opacity: 0.3;
            }
            svg {
                width: 25px;
                g {
                    path {
                        fill: color.$Neutral24;
                    }
                }
            }
        }
        .p25_like_btn {
            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: radial-gradient(circle, color.$Pink10 10%, transparent 10%) no-repeat 50%;
                transform: scale(10, 10);
                opacity: 0;
                transition:
                    transform 0.3s,
                    opacity 1s;
            }
            &:hover {
                background-color: rgba(color.$Pink10, 0.1);
                svg {
                    g {
                        path {
                            fill: color.$Pink10;
                        }
                    }
                }
                span {
                    color: color.$Pink10;
                }
            }
        }
        .liked {
            background-color: rgba(color.$Pink10, 0.15);
            svg {
                g {
                    path {
                        fill: color.$Pink10;
                    }
                }
            }
            span {
                color: color.$Pink10;
            }
        }
        .p25_bookmark_btn {
            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: radial-gradient(circle, color.$Primary40 10%, transparent 10%) no-repeat 50%;
                transform: scale(10, 10);
                opacity: 0;
                transition:
                    transform 0.3s,
                    opacity 1s;
            }
            &:hover {
                background-color: rgba(color.$Primary40, 0.1);
                svg {
                    g {
                        path {
                            fill: color.$Primary40;
                        }
                    }
                }
            }
        }
        .bookmarked {
            background-color: rgba(color.$Primary40, 0.15);
            svg {
                g {
                    path {
                        fill: color.$Primary40;
                    }
                }
            }
        }
        .p25_share_btn {
            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: radial-gradient(circle, color.$Neutral0 10%, transparent 10%) no-repeat 50%;
                transform: scale(10, 10);
                opacity: 0;
                transition:
                    transform 0.3s,
                    opacity 1s;
            }
            &:hover {
                background-color: rgba(color.$Neutral0, 0.1);
            }
        }
        .p25_deploy_btn,
        .p25_github_btn {
            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: radial-gradient(circle, color.$Neutral0 10%, transparent 10%) no-repeat 50%;
                transform: scale(10, 10);
                opacity: 0;
                transition:
                    transform 0.3s,
                    opacity 1s;
            }
            &:hover {
                background-color: rgba(color.$Neutral0, 0.1);
            }
        }
    }
}

.p25_lang_wrapper,
.p25_tech_wrapper {
    @media screen and (max-width: 767.9px) {
        width: 85%;
    }
    .p25_lang_title,
    .p25_tech_title {
        font-weight: bold;
    }
    .p25_lang_item,
    .p25_tech_item {
        width: fit-content;
        font-family: AtkinsonHyperlegible-Regular;
        background-color: color.$Primary40;
        color: color.$Neutral99;
        border-radius: 3px;
        &:first-of-type {
            margin-left: 0 !important;
        }
        &:last-of-type {
            margin-right: 0 !important;
        }
    }
}

.p25_container {
    max-width: 100%;
    margin: 0;
    margin-top: 60px;
    margin-bottom: 60px;
    padding-top: 60px;
    border-top: solid 1px;
    .editor-code:before {
        background-color: color.$Neutral80;
    }
    > .editor-input {
        padding: 0;
    }
    @media screen and (max-width: 767.9px) {
        margin: 0 20px;
    }
}
