@use "../../styles/settings/_Color.scss" as color;

.m4_wrapper {
    padding: 0 50px;
    > .m4_input_label_wrapper {
        padding: 50px 0;
        border-bottom: 1px solid rgba(color.$Neutral_Variant80, 0.5);
        display: flex !important;
        flex-direction: column;
        margin: auto;
        > .m4_input_label {
            min-width: 145px;
            margin-right: 5%;
            margin-bottom: 1rem;
        }
        > div .m43_radio {
            padding-left: 0;
        }
        > .m41_select_wrapper {
            width: 100%;
            > .css-13cymwt-control {
                background: color.$Neutral_Variant95;
            }
            > .css-t3ipsp-control {
                background: color.$Neutral_Variant95;
            }
        }

        > .m4_input_number_field {
            max-width: 220px;
            width: 100%;
        }
        > div .m4_input_number_value {
            line-height: 2;
            width: 100%;
        }
        // > .m4_input_number_field {
        //     width: 220px;
        // }
        // > .m4_input_number_value {
        //     line-height: 2;
        //     margin: 0 0 0 10px;
        // }
        > .m4_input_date_field {
            max-width: 220px;
            width: 100%;
            &::-webkit-calendar-picker-indicator:hover {
                background-color: color.$Neutral87;
                border-radius: 100%;
            }
        }

        > input,
        textarea {
            width: 100%;
            height: 100%;
            min-height: 35px;
            color: color.$Neutral10;
            border-radius: 3px;
            border: 2px solid color.$Neutral_Variant80;
            background: color.$Neutral_Variant95;
            box-shadow: 0px 1px 3px 0px rgba(color.$Neutral10, 0.25) inset;

            &:hover,
            &:focus {
                border: 2px solid color.$Primary40;
                background: color.$Neutral_Variant95;
                box-shadow: none;
            }

            &:focus::placeholder {
                color: transparent;
            }

            &::placeholder {
                color: rgba(color.$Neutral10, 0.5);
            }

            &::-webkit-scrollbar {
                width: 8px;
                height: 8px;
                background: color.$Neutral90;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb {
                background: color.$Neutral80;
                border-radius: 10px;

                &:hover {
                    background: color.$Neutral70;
                }

                &:active {
                    background: color.$Neutral60;
                }
            }
        }

        > input.error {
            border: 2px solid color.$Error40;
        }
        > .m4_developers_wrapper {
            display: flex;
            align-items: center;

            > input {
                width: 100%;
                min-height: 35px;
                color: color.$Neutral10;
                border-radius: 3px;
                border: 2px solid color.$Neutral_Variant80;
                background: color.$Neutral_Variant95;
                box-shadow: 0px 1px 3px 0px rgba(color.$Neutral10, 0.25) inset;

                &:hover,
                &:focus {
                    border: 2px solid color.$Primary40;
                    background: color.$Neutral_Variant95;
                    box-shadow: none;
                }

                &:focus::placeholder {
                    color: transparent;
                }

                &::placeholder {
                    color: rgba(color.$Neutral10, 0.5);
                }

                &::-webkit-scrollbar {
                    width: 8px;
                    height: 8px;
                    background: color.$Neutral90;
                    border-radius: 10px;
                }

                &::-webkit-scrollbar-thumb {
                    background: color.$Neutral80;
                    border-radius: 10px;

                    &:hover {
                        background: color.$Neutral70;
                    }

                    &:active {
                        background: color.$Neutral60;
                    }
                }
            }

            > p {
                margin: 0;
            }
        }
    }
    .m4_button {
        border: none;
        background: color.$Primary40;
        width: 260px;
        height: 60px;
        box-shadow: 0px 1px 3px 0px rgba(color.$Neutral10, 0.25);
        display: block;
        margin: 60px auto;

        &:hover {
            background: color.$Primary30;
        }

        &:active {
            background: color.$Primary20 !important;
        }
    }
    .m4_button_edit {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .m4_button_del {
        border: none;
        display: block;
        margin: 60px auto;
        width: 260px;
        height: 60px;
        border: solid 1px color.$Error50;
    }
}
