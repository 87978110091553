@use "../../../styles/settings/_Color.scss" as color;
@use "../../../styles/settings/_FontFamily.scss" as font-family;
$md: 767.9px;

.p19_title {
  font-size: 24px;
  margin-top: 65px;
}
.p19_wrapper {
  > .list-group {
    @media screen and (max-width: $md) {
      & {
        width: 90%;
        margin: auto;
      }
    }
    width: 20%;
    --bs-list-group-bg: none;
    --bs-list-group-border-color: none;
    --bs-list-group-active-bg: none;
    --bs-list-group-action-hover-bg: none;
    --bs-list-group-action-active-bg: none;
    > .list-group-item {
      font-size: 16px;
      margin: 2rem 0 1rem;
    }
    > div {
      > .list-group-item {
        color: color.$Neutral20;
        font-size: 14px;
      }
      > .list-group-item-action {
        cursor: pointer;
      }
      > .list-group-item-action:not(.active):hover {
        opacity: 0.7;
      }
      > .active {
        color: color.$Primary40;
      }
    }
  }
  > .p19_tab_content {
    @media screen and (max-width: $md) {
      & {
        margin: auto;
      }
    }
    width: 75%;
    > .tab-pane {
      > .p19_accordion_wrapper {
        --bs-border-color: none;
        --bs-accordion-border-radius: unset;
        --bs-accordion-inner-border-radius: unset;
        --bs-accordion-btn-focus-box-shadow: unset;
        > .accordion-item {
          border-bottom: 1px solid rgba(color.$Neutral_Variant50, 30%);
          > .accordion-header {
            > .accordion-button {
              display: block;
              position: relative;
              background: none;
              color: color.$Neutral10;
              padding: 2rem 0 2rem 3rem;
              font-weight: bold;
              &::before {
                content: "";
                width: 1rem;
                height: 1rem;
                margin-left: 1rem;
                position: absolute;
                transform: translate(0, -50%);
                top: 50%;
                left: 0;
                transition: background 0.3s ease, transform 0.3s ease;
                background: url(../../../assets/add.svg) no-repeat center center;
              }
              &:not(.collapsed)::before {
                background: url(../../../assets/minus.svg) no-repeat center
                  center;
                transform: translate(0, -50%) rotate(180deg);
              }
            }
          }
          > .accordion-collapse {
            > .accordion-body {
              font-size: 14px;
              color: color.$Secondary50;
            }
          }
        }
      }
    }
  }
}
