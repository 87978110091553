@use "../../styles/settings/_Color.scss" as color;

.o2_tabs {
	width: 100%;
	padding: 15px 0;
	border-bottom: 1px solid color.$Neutral_Variant80;

	>div {
		flex: 1;
		display: flex;
		justify-content: center;

		>button {
			white-space: nowrap;
			background: transparent;
			border: none;
			border-radius: 5px;
			padding: 12px 60px;
			text-wrap: nowrap;
			font-weight: bold;
			color: color.$Neutral20;
			transition: all .25s ease-in;

			&:hover {
				background: color.$Primary90;
				color: color.$Primary40;
			}

			&:active {
				background: color.$Primary80;
			}
		}

		>.active {
			background: color.$Primary90;
			color: color.$Primary40;
		}
	}
}