@use "../../../styles/settings/_Color.scss" as color;
@use "../../../styles/settings/FontFamily" as font_family;

.o44_modal_title {
    align-items: flex-start !important;
    > p {
        width: 80%;
    }
}

.o44_modal_body {
    width: 80%;
    margin: 0 auto;
    > p {
        word-break: break-word;
        text-align: center;
        > span {
            font-weight: bold !important;
        }
    }
    > .o44_modal_link_content {
        height: 50%;
        margin-top: 50px;
        justify-content: space-evenly;
        a {
            width: 222px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: solid 1px color.$Primary40;
            border-radius: 5px;
            font-family: BIZUDGothic-Bold;
            color: color.$Primary40;
            &:hover {
                opacity: 0.7;
            }

            &:active {
                background-color: color.$Primary95;
            }
        }
        a:first-child {
            background-color: color.$Primary40;
            color: color.$Primary100;
            &:hover {
                background-color: color.$Primary30;
                transition: background-color 0.3s;
            }

            &:active {
                background-color: color.$Primary20;
            }
        }
    }
}

.modal-content:has(.o44_modal_body) {
    height: 400px;
}
