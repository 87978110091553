@use "../../styles/settings/_Color.scss" as color;
@use "../../styles/settings/_FontFamily.scss" as font_family;

.m17_provider {
	width: 246px;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-left: auto;
	margin-right: auto;

	>.or_bar {
		width: 100%;
		font-family: 'AtkinsonHyperlegible-Bold', 'BIZUDGothic-Bold';
		position: relative;
		font-size: 13px;
		display: flex;
		justify-content: center;
		margin: 10px 0;

		&::after,
		&::before {
			position: absolute;
			top: 49%;
			right: 0;
			content: "";
			display: block;
			width: 100px;
			height: 1px;
			background-color: #293055;
		}

		&::before {
			left: 0;
		}
	}

	>.google {
		background-color: color.$Error50;
		border: 1px solid color.$Error50;
		color: #F8F8F8;
        filter: drop-shadow(0px 1px 1px color.$Neutral60);

        &:hover {
            background-color: color.$Error40 !important;
        }

        &:active {
            background-color: color.$Error30 !important;
        }
	}

	>.github {
		background-color: color.$Neutral100 !important;
		border: 1px solid #000;
		color: #293055;
        filter: drop-shadow(0px 1px 1px color.$Neutral60);

        &:hover {
            background-color: color.$Neutral95 !important;
        }

        &:active {
            background-color: color.$Neutral90 !important;
        }
	}

	>.sns_button {
		margin-bottom: 19px;
		width: 246px;
		background-repeat: no-repeat;
		background-size: 40px 40px;
		display: block;
		height: 40px;
		border-radius: 4px;
		line-height: 40px;
		text-align: center;
		font-size: 14px;
		box-sizing: border-box;

		>.sns_label {
			font-family: 'Inter';
			font-weight: bold;
			display: block;
			margin: 0 0 0 42px;
			font-weight: bold;
			border-radius: 4px;
		}
	}

	>.sns_button:last-of-type {
		margin-bottom: 0;
	}
}
