@use "../../styles/settings/_Color.scss" as color;

.a14_inputGroup {
    width: clamp(215px, 30vw, 410px);
    height: 32px;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row-reverse;

    >.input-group-text,
    >.form-control {
        background-color: color.$Neutral99;
    }

    >.a14_inputGroupText {
        border-radius: 5px 0 0 5px !important;
    }

    >.form-control {
        animation: none;
        transform: none;
        transition: unset;
        border-radius: 0 5px 5px 0 !important;

        &:focus {
            outline: none;
            box-shadow: none;
            border-color: color.$Neutral70;

            &::placeholder {
                color: rgba(0, 0, 0, 0);
            }
        }

        &:focus+.a14_inputGroupText {
            border-color: color.$Neutral70;

            >svg {
                opacity: 0.35 !important;
            }
        }
    }
}

@media (max-width: 850px) {
    .a14_inputGroup {
        width: 100%;

        >.input-group {
            >.form-control::placeholder {
                color: rgba(0, 0, 0, 0);
            }
        }
    }
}
